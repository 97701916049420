<template>
  <add-bot-section
    :title="lang.botMaker.addBot.basicSettings.title[languageSelected]"
  >
    <template slot="whole-w">
      <new-form-group
        :label="lang.botMaker.addBot.generalSettings.name[languageSelected]"
      >
        <vs-input
          class="fill"
          v-model="bot.service.name"
          :danger="validations.service.name"
          data-cy="settingNameBot"
          :danger-text="
            lang.botMaker.addBot.generalSettings.error.name.empty[
              languageSelected
            ]
          "
        />
      </new-form-group>
      <new-form-group
        :label="
          lang.botMaker.addBot.generalSettings.description[languageSelected]
        "
      >
        <vs-textarea
          v-model="bot.service.description"
          v-bind:class="{ error: validations.service.description }"
          data-cy="settingDescriptionbot"
        />
        <p class="error-message" v-show="validations.service.description">
          {{
            lang.botMaker.addBot.generalSettings.error.description.empty[
              languageSelected
            ]
          }}
        </p>
      </new-form-group>
    </template>
  </add-bot-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NewFormGroup from './Components/NewFormGroup'
import AddBotSection from './Components/AddBotSection'

export default {
  name: 'BasicSetting',
  components: {
    AddBotSection,
    NewFormGroup
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady'])
  },
  watch: {
    'bot.service.name'(val) {
      this.bot.service.name = val.replace(/\//g, '')
    }
  },
  mounted() {
    this.sectionReady.generalSetting = true
  }
}
</script>
