<template>
  <div v-if="widget" class="widget_settings pt-3 img-drop-shadow">
    <vs-row class="widget_settings-row">
      <vs-col vs-w="12">
        <div class="widget-settings-section expanded" height="100%">
          <div ref="widget_settings_content" class="widget_settings_content">
            <!-- Domain -->
            <!-- <new-form-group
              :label="lang.botMaker.addBot.widget.domain[languageSelected] + ':'"
            >
              <vs-input
                placeholder="www.example.com"
                class="fill"
                id="widget_domain"
                v-model="widget.domain"
                :danger="error.domain && !widget.domain"
                :danger-text="
                  lang.botMaker.addBot.widget.error.domain.empty[languageSelected]
                "
              />
            </new-form-group> -->
            <vs-collapse>
              <vs-collapse-item :open="true">
                <div slot="header">
                  <h2>{{lang.botMaker.addBot.widget.bubbleTitle[languageSelected] }}</h2>
                </div>
                <vs-row vs-w="12" class="mt-4">
                  <vs-col vs-lg="9" class="col-md-100">
                    <SizeSelector
                            :key="keySize"
                            :bubbleSize="true"
                            :heightE="widget.presentation.size"
                            @height="setSizeBubble"
                            ref="sizeSelectorBubble"
                            :enable_height="true"
                    />
                  </vs-col>
                </vs-row>
                <div v-if="widget.presentation.size === 'LARGE'">
                  <new-form-group
                    :tooltip="
                      lang.botMaker.addBot.widget.tooltip.padding[languageSelected]
                    "
                    :header="lang.botMaker.addBot.widget.padding.title[languageSelected] + ':'"
                    class="mt-12"
                  >
                    <vs-row class="widget_settings_padding_row" vs-w="9"> 
                      <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                        <label for="">{{ lang.botMaker.addBot.widget.padding.top[languageSelected] }}</label>
                        <vs-input-number
                          min="0"
                          v-model="widget.presentation.padding.top"
                        />
                      </vs-col>
                      <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                        <label for="">{{ lang.botMaker.addBot.widget.padding.right[languageSelected] }}</label>
                        <vs-input-number
                          min="0"
                          v-model="widget.presentation.padding.right"
                        />
                      </vs-col>
                      <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                        <label for="">{{ lang.botMaker.addBot.widget.padding.left[languageSelected] }}</label>
                        <vs-input-number
                          min="0"
                          v-model="widget.presentation.padding.left"
                        />
                      </vs-col>
                      <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                        <label for="">{{ lang.botMaker.addBot.widget.padding.bottom[languageSelected] }}</label>
                        <vs-input-number
                          min="0"
                          v-model="widget.presentation.padding.bottom"
                        />
                      </vs-col>
                    </vs-row>
                  </new-form-group>
                  <div>
                    <div class="mt-12 mb-4">
                      <h3>{{lang.botMaker.addBot.widget.desktopMobile[languageSelected] }}</h3>
                    </div>
                    <vs-tabs>
                      <vs-tab :vs-label="lang.botMaker.addBot.widget.desktop[languageSelected]" @click="sel = 'desktop'" :color="gCSelDM(sel, 'desktop')">
                      </vs-tab>
                      <vs-tab  :vs-label="lang.botMaker.addBot.widget.mobile[languageSelected]" @click="sel = 'mobile'" :color="gCSelDM(sel, 'mobile')" >
                      </vs-tab>
                    </vs-tabs>
                  </div>
                  <div class="widget_settings_advance">
                    <vs-row vs-w="12">
                      <vs-col vs-w="12">
                        <h3>{{ translateDM('imageSize') }}</h3>
                        <vs-row vs-w="9">
                          <vs-col vs-lg="4" class="col-md-100">
                            <label for="">{{ translateDM('width') }} (px)</label>
                            <div style="display:flex;">
                              <vs-input
                                type="text"
                                v-if="widget.presentation.width[sel].txt"
                                readonly
                                v-model="widget.presentation.width[sel].txt"
                              >
                              </vs-input>
                              <vs-input-number
                                v-else
                                min="0"
                                v-model="widget.presentation.width[sel].px"
                              />
                              <vs-button
                                class="enable_auto_btn overflow-visible"
                                type="flat"
                                @click="enableDisableAuto(widget.presentation.width[sel])"
                                >
                                {{ getTextAuto(widget.presentation.width[sel]) }}
                                </vs-button
                              >
                            </div>
                          </vs-col>
                          <vs-col vs-lg="4" class="col-md-100">
                            <label for="">{{ translateDM('height') }} (px)</label>
                            <div style="display:flex;">
                              <vs-input
                                type="text"
                                v-if="widget.presentation.height[sel].txt"
                                readonly
                                v-model="widget.presentation.height[sel].txt"
                                >
                              </vs-input>
                              <vs-input-number
                                v-else
                                min="0"
                                v-model="widget.presentation.height[sel].px"
                              />
                              <vs-button
                                class="enable_auto_btn overflow-visible"
                                type="flat"
                                @click="enableDisableAuto(widget.presentation.height[sel])"
                                >{{
                                getTextAuto(widget.presentation.height[sel])
                              }}
                              </vs-button>
                            </div>
                          </vs-col>
                          <vs-col vs-offset="0" vs-w="8" class="mt-0">
                            <vs-button class="restore_btn" type="border" @click="restoreSize(sel)"
                              >{{ translateDM('restoreDefault') }}
                              {{ translateDM('imageSize') }}</vs-button
                            >
                          </vs-col>
                        </vs-row>
                      </vs-col>
                      <vs-col vs-w="12">
                        <h3>{{ translateDM('borderRadius') }} (px)</h3>
                        <vs-row vs-w="9">
                          <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                            <label>{{ lang.botMaker.addBot.widget.borderRadius.corner.bottomLeft[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.borderRadius[sel].bottomLeft"
                            />
                          </vs-col>
                          <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                            <label>{{ lang.botMaker.addBot.widget.borderRadius.corner.bottomRight[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.borderRadius[sel].bottomRight"
                            />
                          </vs-col>
                          <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                            <label>{{ lang.botMaker.addBot.widget.borderRadius.corner.topLeft[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.borderRadius[sel].topLeft"
                            />
                          </vs-col>
                          <vs-col vs-lg="2" class="col-md-50 col-sm-100 align-bottom">
                            <label>{{ lang.botMaker.addBot.widget.borderRadius.corner.bottomRight[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.borderRadius[sel].topRight"
                            />
                          </vs-col>
                        </vs-row>
                        <vs-button class="restore_btn" type="border" @click="restorerBorderRadius(sel)"
                          >{{ translateDM('restoreDefault') }}
                          {{ translateDM('borderRadius') }}</vs-button
                        >
                      </vs-col>
                      <vs-col vs-w="12" class="mt-6">
                        <h3>{{ translateDM('position') }} (px)</h3>
                        <vs-row class="position_row" vs-w="9">
                          <vs-col vs-w="4" class="col-md-50 col-sm-100">
                            <label>{{ lang.botMaker.addBot.widget.position.bottom[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.position[sel].bottom"
                            />
                          </vs-col>
                          <vs-col vs-w="4" class="col-md-50 col-sm-100">
                            <label>{{ lang.botMaker.addBot.widget.position.right[languageSelected] }}</label>
                            <vs-input-number
                              min="0"
                              v-model="widget.presentation.position[sel].right"
                            />
                          </vs-col>
                        </vs-row>
                        <vs-button class="restore_btn" type="border" @click="restorePosition(sel)"
                          >{{ translateDM('restoreDefault') }}
                          {{ translateDM('position') }}</vs-button
                        >
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <new-form-group :header="lang.botMaker.addBot.widget.presentation[languageSelected] + ':'"
                    class="mt-12" >
                  <vs-row vs-w="12">
                    <vs-col vs-w="9" class="col-md-100 mt-2">
                      <label for="">{{
                        lang.botMaker.addBot.widget.text[languageSelected]
                        }}:</label>
                      <vs-input
                              id="widget_bubble_text"
                              class="w-100"
                              v-model="widget.presentation.text"
                              :danger="error.presentation.text"
                              :danger-text="
                        lang.botMaker.addBot.widget.error.text.empty[languageSelected]
                      "
                      />
                    </vs-col>
                    <vs-col vs-lg="3" class="col-md-100 mt-2 pl-6 pl-md-0">
                      <div class="vs-input-number-container">
                        <label for="">{{
                          lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                          languageSelected
                          ]
                          }}</label>
                        <vs-input-number
                                id="widget_bubble_text_size"
                                min="0"
                                v-model="widget.fontSizes.presentation"
                        />
                      </div>
                    </vs-col>
                </vs-row>
              </new-form-group>

              <new-form-group>
                <vs-row vs-w="12">
                  <!-- Image -->
                  <vs-col vs-w="12" class="col-md-100">
                    <div class="widget_settings_konaUpload">
                      <label for="">
                        {{ lang.botMaker.addBot.widget.image[languageSelected] }}
                      </label>
                      <BotSettingsMedia
                              :savedFile="getFile(widget.presentation.image)"
                              :key="keyImage"
                              @onChange="updateFile('widget.presentation.image', ...arguments)"
                              ref="konaUpload"
                      />
                    </div>
                  </vs-col>
                  <vs-col vs-w="12">
                  <!-- <div style="display: flex; flex-direction: column" class="container_colors"> -->
                    <vs-row vs-w="9" class="container_colors mt-4">
                      <vs-col vs-lg="3" class="flex flex-col mt-4 align-bottom">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.color[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.presentation.color" />
                      </vs-col>

                      <vs-col vs-lg="3" class="flex flex-col mt-4 align-bottom">
                        <label for="">
                          {{lang.botMaker.addBot.widget.textColorPresentation[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.presentation.textColor" />
                      </vs-col>
                    </vs-row>
                  <!-- </div> -->
                  </vs-col>
                </vs-row>
              </new-form-group>
              </vs-collapse-item>

              <vs-collapse-item>
                <div slot="header">
                  <h2>{{ lang.botMaker.addBot.widget.chatWindowTitle[
                  languageSelected] }}</h2>
                </div>
                <vs-row vs-w="12">
                  <vs-col vs-lg="9" class="col-md-100 mt-2">
                    <SizeSelector
                            :key="keySize * 2"
                            :heightE="widget.chat.size"
                            @height="setHeight"
                            ref="sizeSelector"
                            :enable_height="true"
                            class="w-100"
                    />
                  </vs-col>
                </vs-row>

                <!-- chatFont -->
                <new-form-group
                  :label="lang.botMaker.addBot.widget.chatFont[languageSelected] + ':'"
                  class=" mt-8"
                >
                  <vs-row vs-w="12">
                    <vs-col vs-lg="9" class="col-md-100">
                      <font-picker
                              :api-key="'AIzaSyBgpgJr06gsCoV6c8C7IAihaucbvRsku3w'"
                              :active-font="widget.chat.fontFamily"
                              @change="font_change"
                              class="w-100"
                      ></font-picker>
                    </vs-col>
                  </vs-row>
                </new-form-group>

                <div class="header-bot"> <!-- Header Bot -->
                  <h3>{{ lang.botMaker.addBot.widget.headerTitle[
                      languageSelected
                    ]}} </h3>
                  <!-- Header Title -->
                  <new-form-group>
                    <vs-row vs-w="12">
                      <vs-col vs-w="9" class="col-md-100 mt-2">
                        <label for="">{{
                          lang.botMaker.addBot.widget.header.title[languageSelected]
                          }}:</label>
                        <vs-input
                                id="widget_header_title"
                                class="w-100"
                                v-model="widget.header.title"
                        />
                      </vs-col>
                      <vs-col vs-lg="3" class="col-md-100 mt-2 pl-6 pl-md-0">
                        <div class="vs-input-number-container">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                            languageSelected
                            ]
                            }}</label>
                          <vs-input-number min="0" v-model="widget.fontSizes.header.title" />
                        </div>
                      </vs-col>
                    </vs-row>
                  </new-form-group>

                  <!-- Header Subtitle -->
                  <new-form-group>
                    <vs-row vs-w="12">
                      <vs-col vs-w="9" class="col-md-100 mt-0">
                        <label for="">{{
                          lang.botMaker.addBot.widget.header.subtitle[languageSelected]
                          }}:</label>
                        <vs-input
                          id="widget_header_subtitle"
                          class="w-100"
                          v-model="widget.header.subtitle"
                        />
                      </vs-col>
                      <vs-col vs-lg="3" class="col-md-100 mt-0 pl-6 pl-md-0">
                        <div class="vs-input-number-container">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                            languageSelected
                            ]
                            }}</label>
                          <vs-input-number
                            min="0"
                            v-model="widget.fontSizes.header.subtitle"
                          />
                        </div>
                      </vs-col>
                    </vs-row>
                  </new-form-group>

                  <!-- Header Image -->
                  <new-form-group
                    :label="
                      lang.botMaker.addBot.widget.header.image[languageSelected] + ':'
                    "
                  >
                    <div id="widget_header_logo" class="widget_settings_konaUpload">
                      <BotSettingsMedia
                        :savedFile="getFile(widget.header.image)"
                        :key="keyImage"
                        @onChange="updateFile('widget.header.image', ...arguments)"
                        ref="konaUpload"
                      />
                    </div>
                  </new-form-group>
                  <!-- header colors -->
                  <div style="display: flex; flex-direction: column" class="container_colors mb-12 mt-6">
                    <vs-row vs-w="9">
                      <vs-col vs-w="3" class="flex flex-col mt-4 align-bottom">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.color[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.header.color.text" />
                      </vs-col>

                      <vs-col vs-w="3" class="flex flex-col mt-4 align-bottom">
                        <label for="">
                          {{lang.botMaker.addBot.widget.textColorPresentation[languageSelected]}}
                        </label>
                        <input type="color" v-model="widget.header.color.bg" />
                      </vs-col>
                    </vs-row>
                  </div>
                </div>

                <div>
                  <h3> {{lang.botMaker.addBot.widget.chatAreaTitle[languageSelected]}} </h3>
                  <div class="d-flex fontSizes_input_buttons">
                    <vs-row class="mt-4" vs-w="9">
                      <vs-col vs-w="4" class="col-md-50 col-sm-100 align-bottom">
                        <div class="vs-input-number-container-text">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitleText[languageSelected] }}</label>
                          <vs-input-number min="0" v-model="widget.fontSizes.chat" />
                        </div>
                      </vs-col>
                      <vs-col vs-w="4" class="col-md-50 col-sm-100 align-bottom">
                        <div class="vs-input-number-container-button">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitleButton[languageSelected] }}</label>
                          <vs-input-number min="0" v-model="widget.fontSizes.button" />
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <vs-row vs-w="12">
                    <vs-col vs-lg="9" class="col-md-100">
                      <SizeSelector
                              :key="keySize * 3"
                              :messageSize="true"
                              :heightE="widget.messageSize"
                              @height="setBotMessageSize"
                              ref="sizeSelectorMessageBubble"
                              :enable_height="true"
                              class="w-100"
                      />
                    </vs-col>
                  </vs-row>

                  <!-- botLogo -->
                  <new-form-group
                    :label="lang.botMaker.addBot.widget.logoBot[languageSelected] + ':'" class=" mt-8"
                  >
                    <div id="widget_logo_bot" class="widget_settings_konaUpload">
                      <BotSettingsMedia
                        :savedFile="getFile(widget.chat.botLogo)"
                        :key="keyImage"
                        @onChange="updateFile('widget.chat.botLogo', ...arguments)"
                        ref="konaUpload"
                      />
                    </div>
                  </new-form-group>

                  <!-- userLogo -->
                  <new-form-group
                    :label="lang.botMaker.addBot.widget.logoUser[languageSelected] + ':'"  class=" mt-8"
                  >
                    <div id="widget_logo_user" class="widget_settings_konaUpload">
                      <BotSettingsMedia
                        :savedFile="getFile(widget.chat.userLogo)"
                        :key="keyImage"
                        @onChange="updateFile('widget.chat.userLogo', ...arguments)"
                        ref="konaUpload"
                      />
                      <div class="flex checkbox-group">
                        <vs-checkbox
                          v-model="widget.disableAvatarUser"
                          id="disableAvatarUser"
                        ></vs-checkbox>
                        <label for="disableAvatarUser">
                          {{
                            lang.botMaker.addBot.widget.disableAvatarUser[languageSelected]
                          }}
                        </label>
                      </div>

                      <vs-button
                        v-if="widget.chat.userLogo != defaultUserLogo"
                        class="restore_btn mt-4"
                        color="primary" type="border"
                        @click="resetUserLogo()"
                      >
                        {{ lang.botMaker.addBot.widget.resetUserLogo[languageSelected] }}
                      </vs-button>
                    </div>
                  </new-form-group>


                  <new-form-group
                    :label="lang.botMaker.addBot.widget.background[languageSelected] + ':'"  class=" mt-8"
                  >
                    <div class="widget_settings_konaUpload">
                      <BotSettingsMedia
                        :savedFile="getFile(widget.presentation.backgroundImg)"
                        :key="keyImage"
                        @onChange="
                          updateFile('widget.presentation.backgroundImg', ...arguments)
                        "
                        ref="konaUpload"
                      />
                      <vs-button
                        v-if="widget.presentation.backgroundImg"
                        class="float-right m-0 w-100 mt-2 mb-6"
                        color="danger"
                        @click="removeBackgroundImage()"
                      >
                        {{ lang.botMaker.addBot.widget.eraseBg[languageSelected] }}
                      </vs-button>
                    </div>
                  </new-form-group>
                </div>

                <div class="mt-12">
                  <h3>{{ lang.botMaker.addBot.widget.welcomeMessage[languageSelected] }}</h3>
                  <!-- chat welcome_message -->
                  <!-- <new-form-group
                    :label="
                      lang.botMaker.addBot.widget.welcomeMessage[languageSelected] + ':'
                    "
                  > -->
                  <div class="d-flex fontSizes_input_buttons">
                    <Message
                      :isWidget="true"
                      ref="buttonMessage"
                      :key="messageBtnKey"
                      class="chat__input flex bg-white"
                      :has-errors="hasError"
                      :maxCharsButton="30"
                      @onTextChangeWidget="onTextButtonsChange"
                    />
                    
                  </div>
                </div>
                <div class="mt-8">
                  <!-- Colors -->
                  <h3> {{lang.botMaker.addBot.widget.colors.botMessages[languageSelected]}} </h3>

                  <div style="display: flex; flex-direction: column" class="container_colors mt-4">
                    <vs-row vs-w="9">
                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{
                            lang.botMaker.addBot.widget.colors.background[languageSelected]
                          }}
                        </label>
                        <input type="color" v-model="widget.chat.botMessage.bg" />
                      </vs-col>

                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.colors.text[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.chat.botMessage.text" />
                      </vs-col>

                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.colors.links[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.chat.botMessage.link" />
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8">
                  <h3> {{lang.botMaker.addBot.widget.colors.userMessages[languageSelected]}} </h3>

                  <div style="display: flex; flex-direction: column" class="container_colors mt-4">
                    <vs-row vs-w="9">
                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{
                            lang.botMaker.addBot.widget.colors.background[languageSelected]
                          }}
                        </label>
                        <input type="color" v-model="widget.chat.userMessage.bg" />
                      </vs-col>

                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.colors.text[languageSelected]}}
                        </label>
                        <input type="color" v-model="widget.chat.userMessage.text" />
                      </vs-col>
                      <!-- <vs-col vs-w="4" class="flex flex-col"></vs-col> -->
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8 mb-8">
                  <h3> {{lang.botMaker.addBot.widget.colors.buttons[languageSelected] }} </h3>

                  <div style="display: flex; flex-direction: column" class="container_colors mt-4">
                    <vs-row vs-w="9">
                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{
                            lang.botMaker.addBot.widget.colors.background[languageSelected]
                          }}
                        </label>
                        <input type="color" v-model="widget.chat.buttons.bg" />
                      </vs-col>
                    
                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{ lang.botMaker.addBot.widget.colors.text[languageSelected] }}
                        </label>
                        <input type="color" v-model="widget.chat.buttons.text" />
                      </vs-col>

                      <vs-col vs-w="3" class="flex flex-col">
                        <label for="">
                          {{
                          lang.botMaker.addBot.widget.colors.border[languageSelected]
                          }}
                        </label>
                        <input type="color" v-model="widget.chat.buttons.border" />
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <!-- Colors -->
                <!-- <table class="w-full pt-2 pb-4 colors-table">
                  <tr>
                    <td>
                      <strong>{{
                        lang.botMaker.addBot.widget.colors.colors[languageSelected]
                      }}</strong>
                    </td>
                    <td>
                      {{ lang.botMaker.addBot.widget.colors.text[languageSelected] }}
                    </td>
                    <td>
                      {{
                        lang.botMaker.addBot.widget.colors.background[languageSelected]
                      }}
                    </td>
                    <td>
                      {{ lang.botMaker.addBot.widget.colors.links[languageSelected] }}
                    </td>
                    <td>
                      {{ lang.botMaker.addBot.widget.colors.border[languageSelected] }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ lang.botMaker.addBot.widget.colors.buttons[languageSelected] }}
                    </td>
                    <td><input type="color" v-model="widget.chat.buttons.text" /></td>
                    <td><input type="color" v-model="widget.chat.buttons.bg" /></td>
                    <td></td>
                    <td><input type="color" v-model="widget.chat.buttons.border" /></td>
                  </tr>
                </table> -->
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">
                  <h2>{{lang.botMaker.addBot.widget.footer[languageSelected] }}</h2>
                </div>
                <new-form-group>
                  <vs-row vs-w="12">
                    <vs-col vs-w="9" class="col-md-100 mt-2">
                      <label for="">{{
                        lang.botMaker.addBot.widget.placeholder[languageSelected]
                        }}:</label>
                      <vs-input
                        id="widget_input_placeholder"
                        class="w-100"
                        v-model="widget.chat.message_placeholder"
                      />
                    </vs-col>
                    <vs-col vs-lg="3" class="col-md-100 mt-1 pl-6 pl-md-0">
                      <div class="vs-input-number-container">
                        <label for="">{{
                          lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                          languageSelected
                          ]
                          }}</label>
                        <vs-input-number min="0" v-model="widget.fontSizes.placeholder" />
                      </div>
                    </vs-col>
                  </vs-row>
                </new-form-group>

                <new-form-group>
                  <vs-row vs-w="12">
                    <vs-col vs-w="9" class="col-md-100 mt-2">
                      <label for="">{{
                        lang.botMaker.addBot.widget.dateplaceholder[languageSelected]
                        }}:</label>
                      <div class="d-flex fontSizes_input">
                        <vs-input
                                id="widget_input_dateplaceholder"
                                class="w-100"
                                v-model="widget.chat.datemessage_placeholder"
                        />
                        <!-- <div class="vs-input-number-container">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                              languageSelected
                            ]
                          }}</label>
                          <vs-input-number min="0" v-model="widget.fontSizes.dateplaceholder" />
                        </div>-->
                      </div>
                    </vs-col>
                  </vs-row>
                </new-form-group>
                <new-form-group>
                  <vs-row vs-w="12">
                    <vs-col vs-w="9" class="col-md-100 mt-2">
                      <label for="">{{
                        lang.botMaker.addBot.widget.noLocationPermissionMessage[languageSelected]
                        }}:</label>
                      <div class="d-flex fontSizes_input">
                        <vs-input
                                id="widget_input_noLocationPermissionMessage"
                                class="w-100"
                                v-model="widget.chat.noLocationPermissionMessage"
                        />
                        <!-- <div class="vs-input-number-container">
                          <label for="">{{
                            lang.botMaker.addBot.widget.fontSizes.containerSubtitle[
                              languageSelected
                            ]
                          }}</label>
                          <vs-input-number min="0" v-model="widget.fontSizes.dateplaceholder" />
                        </div>-->
                      </div>
                    </vs-col>
                  </vs-row>
                </new-form-group>
                <new-form-group>
                  <div class="flex items-center">
                    <vs-checkbox
                            v-model="widget.chatLink.show"
                            id="showChatLink"
                    ></vs-checkbox>
                    <label for="showChatLink">
                      {{ lang.botMaker.addBot.widget.chatLink.show[languageSelected] }}
                    </label>
                    <vx-tooltip
                            :text="lang.botMaker.addBot.widget.chatLink.description[languageSelected]+widget.chatLink.text"
                            position="top"
                    >
                      <feather-icon
                              icon="InfoIcon"
                              svgClasses="h-5 w-5"
                              class="ml-2 mt-1"
                      />
                    </vx-tooltip>
                  </div>
                </new-form-group>
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">
                  <h2>{{lang.botMaker.addBot.widget.actionTitle[
                  languageSelected
                ]}}</h2>
                </div>
                <!-- historyChat -->
                <new-form-group>
                  <div class="flex items-center">
                    <vs-checkbox
                      v-model="widget.features.historyChat"
                      id="historyChat"
                    ></vs-checkbox>
                    <label for="historyChat">
                      {{ lang.botMaker.addBot.widget.history[languageSelected] }}
                    </label>
                  </div>
                </new-form-group>

                <!-- reloadChat -->
                <new-form-group>
                  <div class="flex items-center">
                    <vs-checkbox
                      v-model="widget.features.reloadChat"
                      id="reload"
                    ></vs-checkbox>
                    <label for="reload">{{
                      lang.botMaker.addBot.widget.reload[languageSelected]
                    }}</label>
                    <vx-tooltip
                      :text="lang.botMaker.addBot.widget.tooltip.reload[languageSelected]"
                      position="top"
                    >
                      <feather-icon
                        icon="InfoIcon"
                        svgClasses="h-5 w-5"
                        class="ml-2 mt-1"
                      />
                    </vx-tooltip>
                  </div>
                </new-form-group>

                <new-form-group
                  v-show="widget.features.reloadChat"
                  class="widget_settings_welcomeMessage"
                  :label="'Reload Message' + ':'"
                >
                  <Message
                    :isWidget="true"
                    ref="buttonMessageReload"
                    :key="messageBtnKey + 1"
                    class="chat__input flex bg-white"
                    :has-errors="hasErrorReload"
                    @onTextChangeWidget="onTextButtonsChangeReload"
                  />
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.reaction"
                      id="reaction"
                    ></vs-checkbox>
                    <label for="reaction">{{
                      lang.botMaker.addBot.widget.reaction[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.startMessage"
                      id="dataState"
                    ></vs-checkbox>
                    <label for="dataState">{{
                      lang.botMaker.addBot.widget.dataState[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.startMinimized"
                      id="startMinimized"
                    ></vs-checkbox>
                    <label for="startMinimized">{{
                      lang.botMaker.addBot.widget.startMinimized[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.enableAudioMessages"
                      id="audioMessages"
                    ></vs-checkbox>
                    <label for="audioMessages">{{
                      lang.botMaker.addBot.widget.enableAudioMessages[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.disableEmojis"
                      id="disableEmojis"
                    ></vs-checkbox>
                    <label for="disableEmojis">{{
                      lang.botMaker.addBot.widget.disableEmojis[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

                <new-form-group>
                  <div class="flex">
                    <vs-checkbox
                      v-model="widget.chat.enableFiles"
                      id="fileMessages"
                    ></vs-checkbox>
                    <label for="fileMessages">{{
                      lang.botMaker.addBot.widget.enableFiles[languageSelected]
                    }}</label>
                  </div>
                </new-form-group>

              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">
                  <h2>{{lang.botMaker.addBot.widget.versionScriptsTitle[languageSelected]}} </h2>
                </div>
                <!-- historyChat -->
                <new-form-group
                  :tooltip="
                    lang.botMaker.addBot.widget.tooltip.scriptVersion[languageSelected]
                  "
                  :label="
                    lang.botMaker.addBot.widget.scriptVersion[languageSelected] + ':'
                  "
                >
                  <vs-row  vs-w="12">
                    <vs-col vs-w="9" class="col-md-100 mt-2">
                      <multiselect
                              v-model="selectedVersion"
                              :options="versions"
                              label="tag"
                              :searchable="false"
                              :show-labels="false"
                              :placeholder="
                        lang.botMaker.addBot.widget.scriptVersion[languageSelected]
                      "
                              :optionWidth="25"
                      />
                    </vs-col>
                  </vs-row>
                </new-form-group>

                <new-form-group
                  :tooltip="lang.botMaker.addBot.widget.tooltip.script[languageSelected]"
                  :label="lang.botMaker.addBot.widget.scriptBot[languageSelected] + ':'"
                >
                  <div class="copyTextArea">
                    <prism inline language="html" class="rounded-lg">
                      {{ script }}
                    </prism>
                  </div>
                  <vs-button
                    color="primary"
                    type="flat" 
                    @click="copyScript()"
                    icon-pack="feather"
                    icon="icon-copy"
                  >
                   
                    {{ lang.botMaker.addBot.widget.copy.script[languageSelected] }}
                  </vs-button>
                </new-form-group>

                <new-form-group
                  :label="lang.botMaker.addBot.widget.mobileVersion[languageSelected] + ':'"
                  >
                  <div class="copyTextArea">
                    <prism inline language="html" class="rounded-lg">
                      {{ mobileVersionUrl }}
                    </prism>
                  </div>
                  <vs-button
                    color="primary"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-copy"
                    v-clipboard="() => mobileVersionUrl"
                    v-clipboard:success="copyMobileVersionUrlSuccess"
                    v-clipboard:error="copyMobileVersionUrlError"
                  >
                    {{ lang.botMaker.addBot.widget.copy.link[languageSelected] }}
                  </vs-button>
                </new-form-group>

              </vs-collapse-item>
            </vs-collapse>
            <!-- <new-form-group :label="lang.botMaker.addBot.widget.chat[languageSelected]">
            </new-form-group>  -->
          </div>
        </div>
      </vs-col>
      <!-- <vs-col vs-w="6">
        <div class="widget-settings-section expanded" height="100%">
          <div ref="widget_settings_content" class="widget_settings_content" > -->
          <!-- <BotWidgetSettings
            v-if="active"
            ref="botWidgetSettings"
            @closePopup="active = false"
            :key="keyBotWidget"
          /> -->
          <!-- </div>
        </div>
      </vs-col> -->
    </vs-row>
    
    <div class="widget_settings_footer footer">
      <vs-button
        :disabled="disablePreview"
        color="primary"
        type="flat"
        @click="preview()"
        v-if="!readOnly"
      >
        <span>
          {{ lang.botMaker.editBot.previewBot[languageSelected] }}
        </span>
      </vs-button>
      <vs-button color="primary" type="flat" @click="restoreDefault()" v-if="!readOnly">
        <span>
          {{
            lang.botMaker.addBot.widget.defaultConfig.restore[languageSelected]
          }}
        </span>
      </vs-button>
      <vs-button color="danger" type="flat" @click="cancel()">{{lang.botMaker.cancel[languageSelected] }}</vs-button>
      <vs-button color="primary" type="filled" @click="save()" v-if="!readOnly">
        <span>
          {{ lang.botMaker.editBot.saveBot[languageSelected] }}
        </span>
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import NewFormGroup from '../Components/NewFormGroup'
import BotMakerService from '@/services/botMaker.service'
import WidgetService from '@/services/fauna.widget.service.js'
import state from '@/store/state'
import { isEqual } from 'lodash'
import Prism from 'vue-prism-component'
import WidgetMixin from '@/mixins/widgetMixin.js'
import WidgetSettingsMixin from '@/mixins/widgetSettingsMixin.js'
import store from '../../../../../store/store'
const backend = store.state.config.konecta
/** Font Picker */
import FontPicker from 'font-picker-vue'

export default {
  name: 'BotWidgetSettings',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mixins: [WidgetMixin, WidgetSettingsMixin],
  components: {
    Multiselect: () => import('vue-multiselect'),
    SizeSelector: () => import('./SizeSelector'),
    FontPicker,
    Prism,
    // AddBotSection,
    NewFormGroup,
    // Photoshop: Chrome,
    Message: () =>
      import(
        '@/views/home/bot-maker/bot-editor/components/botSays/buttonsMessage/Message'
      ),
    BotSettingsMedia: () => import('./BotSettingsMedia.vue')
  },
  data() {
    return {
      sel: 'desktop',
      defaultUserLogo: `${state.config.konectaWidget}/assets/user.svg`,
      popupSaveDefault: '',
      widgetFaunaId: '',
      configDomainBot: {
        _id: '',
        domain: '',
        id: ''
      },
      font: {
        options: {
          name: 'widget__name',
          limit: 100
        }
      },
      hasError: '',
      hasErrorReload: '',
      error: {
        domain: false,
        presentation: {
          text: false
        }
      },
      hasErrorButtons: false,
      messageBtnKey: 1,
      keyImage: new Date().getTime(),
      keySize: new Date().getTime() * 2,
      defaultWidget: null,
      widget: null,
      selectedVersion: null,
      showMobile: false
    }
  },
  computed: {
    disablePreview() {
      return !this.widgetFaunaId
    },
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapGetters('botMaker', [
      'botActiveVersion',
      'botVersions',
      'activeVersion'
    ]),
    ...mapState(['lang', 'languageSelected', 'config']),
    versions() {
      return this.botVersions.map(item => ({
        tag: item.tag,
        data: {
          _id: item._id,
          active: item.active
        }
      }))
    },
    script() {
      const domainKonecta = this.config.konectaWidget
      let versionIdSection = ''
      if (
        this.selectedVersion &&
        this.selectedVersion.data &&
        !this.selectedVersion.data.active
      ) {
        versionIdSection = `data-versionId="${this.selectedVersion.data._id}" `
      }
      const res = `<script data-botId="${this.bot.id}" ${versionIdSection}id="konecta-widget" src="${domainKonecta}/konecta-widget.js"><\/script>`
      return res
    },
    mobileVersionUrl() {
      let url = `${this.config.konectaWidget}/?botId=${this.bot.id}&mobile=true`

      if (
        this.selectedVersion &&
        this.selectedVersion.data &&
        !this.selectedVersion.data.active
      ) {
        url = url.concat(`&versionId=${this.selectedVersion.data._id}`)
      }
      return url
    }
  },
  watch: {
    languageSelected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateLanguage(oldVal)
      }
    }
  },
  beforeMount() {
    this.start()
  },
  mounted(){
    const widgetStyle = document.createElement('style');
    widgetStyle.setAttribute('id', 'widget-settings-style');
    widgetStyle.textContent =
            '#chat-konecta .sc-launcher {' +
            '    bottom: 140px !important;' +
            '}' +
            '#chat-konecta .sc-launcher.opened:before {' +
            '    box-shadow: 0 -280px 200px 250px rgb(148 149 150 / 20%) !important;' +
            '}' +
            '#chat-konecta .sc-chat-window {' +
            '    max-height: calc(100% - 300px) !important;' +
            '    bottom: 225px !important;' +
            '}' +
            '@media only screen and (max-height: 842px) {' +
            '  #chat-konecta .sc-launcher {' +
            '    bottom: 120px !important;' +
            '  }' +
            '  #chat-konecta .sc-chat-window {' +
            '    max-height: calc(100% - 250px) !important;' +
            '    bottom: 210px !important;' +
            '  }' +
            '}'+
            '@media only screen and (max-height: 715px) {' +
            '  #chat-konecta .sc-launcher {' +
            '    bottom: 100px !important;' +
            '  }' +
            '  #chat-konecta .sc-chat-window {' +
            '    max-height: calc(100% - 200px) !important;' +
            '    bottom: 180px !important;' +
            '  }' +
            '}'+
            '@media only screen and (max-height: 500px) {' +
            '  #chat-konecta .sc-launcher {' +
            '    bottom: 30px !important;' +
            '  }' +
            '  #chat-konecta .sc-chat-window {' +
            '    max-height: calc(100% - 150px) !important;' +
            '    bottom: 120px !important;' +
            '  }' +
            '}';
    document.head.append(widgetStyle);
  },
  beforeDestroy(){
    this.pauseWidget();
    const widgetStyle = document.getElementById('widget-settings-style');
    if(widgetStyle) widgetStyle.remove();
  },
  methods: {
    ...mapMutations('bots',[
      'SET_WIDGET_SETTINGS_OPEN',
    ]),
    preview() {
      let versionId =
        this.botActiveVersion && !this.botActiveVersion.active
          ? this.botActiveVersion._id
          : this.activeVersion
      if (!versionId) {
        versionId = this.$route.query.version
          ? this.$route.query.version
          : this.$route.query.versionId
      }
      this.pauseWidget()
      this.previewWidget(this.bot.id, versionId, null, false, this.bot && this.bot._nlu ? this.bot._nlu._culture : undefined)
    },
    openAccordion() {
      setTimeout(() => {
        const accordion = document.querySelector('.vs-collapse-item--header')
        accordion.click()
      }, 1500)
    },
    copyScript() {
      let dummy = document.createElement('input')
      let text = this.script

      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$notify.success(
        this.lang.attentionRequests.navbar.copySuccess.title[
          this.languageSelected
        ],
        this.lang.botMaker.addBot.copySuccess.text[this.languageSelected]
      )
    },
    gCSelDM(sel, option) {
      if (sel === option) {
        return 'grey'
      } else {
        return 'primary'
      }
    },
    restoreSize(sel) {
      const dW = WidgetService.getDefault(this.lang, this.languageSelected)
      this.widget.presentation.height[sel] = dW.presentation.height[sel]
      this.widget.presentation.width[sel] = dW.presentation.width[sel]
    },
    restoreFontSize() {
      const dW = WidgetService.getDefault(this.lang, this.languageSelected)
      this.widget.fontSizes = dW.fontSizes
    },
    restorerBorderRadius(sel) {
      const dW = WidgetService.getDefault(this.lang, this.languageSelected)
      this.widget.presentation.borderRadius[sel] =
        dW.presentation.borderRadius[sel]
    },
    restorePosition(sel) {
      const dW = WidgetService.getDefault(this.lang, this.languageSelected)
      this.widget.presentation.position[sel] = dW.presentation.position[sel]
    },
    translateDM(textDesktopMobile) {
      return this.lang.botMaker.addBot.widget[textDesktopMobile][
        this.languageSelected
      ]
    },
    enableDisableAuto(item) {
      if (item.txt === 'auto') {
        item.txt = ''
      } else {
        item.txt = 'auto'
      }
    },
    getTextAuto(item) {
      if (item.txt) {
        return this.translateDM('disable') + ' Auto'
      } else {
        return this.translateDM('enable') + ' Auto'
      }
    },
    removeBackgroundImage() {
      this.widget.presentation.backgroundImg = ''
      this.keyImage = new Date().getTime()
    },
    resetUserLogo() {
      this.widget.chat.userLogo = this.defaultUserLogo + ''
      this.keyImage = new Date().getTime()
    },
    updateItemLang(key, item, itemLang, oldLang, newLang) {
      if (item === itemLang[oldLang]) {
        const nL = itemLang[newLang]
        switch (key) {
          case 'presentation.text':
            this.widget.presentation.text = nL
            break

          case 'header.title':
            this.widget.header.title = nL
            break

          case 'chat.message_placeholder':
            this.widget.chat.message_placeholder = nL
            break
          case 'chat.datemessage_placeholder':
            this.widget.chat.datemessage_placeholder = nL
            break
          case 'chat.noLocationPermissionMessage':
            this.widget.chat.noLocationPermissionMessage = nL
            break
          case 'chat.welcome_message.text':
            this.widget.chat.welcome_message.text = [nL]
            break
          default:
          // code block
        }
      }
    },
    updateLanguage(oldLang) {
      /** Reload Size Selector */
      this.keySize = new Date().getTime()
      /** Reload Size Selector */
      const lang = this.lang
      const languageSelected = this.languageSelected
      this.updateItemLang(
        'presentation.text',
        this.widget.presentation.text,
        lang.botMaker.addBot.widgetDefault.presentation.text,
        oldLang,
        languageSelected
      )

      this.updateItemLang(
        'header.title',
        this.widget.header.title,
        lang.botMaker.addBot.widgetDefault.header.title,
        oldLang,
        languageSelected
      )

      this.updateItemLang(
        'chat.message_placeholder',
        this.widget.chat.message_placeholder,
        lang.botMaker.addBot.widgetDefault.chat.message_placeholder,
        oldLang,
        languageSelected
      )

      this.updateItemLang(
        'chat.datemessage_placeholder',
        this.widget.chat.datemessage_placeholder,
        lang.botMaker.addBot.widgetDefault.chat.datemessage_placeholder,
        oldLang,
        languageSelected
      )

      this.updateItemLang(
        'chat.noLocationPermissionMessage',
        this.widget.chat.noLocationPermissionMessage,
        lang.botMaker.addBot.widgetDefault.chat.noLocationPermissionMessage,
        oldLang,
        languageSelected
      )

      this.updateItemLang(
        'chat.welcome_message.text',
        this.widget.chat.welcome_message.text[0],
        lang.botMaker.addBot.widgetDefault.welcome_message.text,
        oldLang,
        languageSelected
      )

      this.setCommon(this.widget)
    },
    loadDefault() {
      this.widget = WidgetService.getDefault(this.lang, this.languageSelected)
      this.setCommon(this.widget)
    },
    setHeight(newHeight) {
      this.widget.chat.size = newHeight
    },
    setSizeBubble(newHeight) {
      this.widget.presentation.size = newHeight
    },
    setBotMessageSize(newHeight) {
      this.widget.messageSize = newHeight
    },
    areYouSureRestoreDefault(cb) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title:
          this.lang.treeView.tree.confirmation.title[this.languageSelected],
        text: this.lang.botMaker.addBot.widget.defaultConfig.textRestore[
          this.languageSelected
        ],
        acceptText:
          this.lang.treeView.tree.confirmation.acceptText[
            this.languageSelected
          ],
        cancelText:
          this.lang.treeView.tree.confirmation.cancelText[
            this.languageSelected
          ],
        accept: () => {
          cb(true)
        },
        cancel: () => {
          cb(false)
        }
      })
    },
    setCommon(widget) {
      this.$nextTick(() => {
        this.font_change({ family: widget.chat.fontFamily })
        this.$refs.sizeSelector.loadHeight(widget.chat.size)
        this.$refs.sizeSelectorBubble.loadHeight(widget.presentation.size)
        this.$refs.sizeSelectorMessageBubble.loadHeight(widget.messageSize)

        this.keyImage = new Date().getTime()
        if (widget.chat.welcome_message) {
          this.$refs.buttonMessage.loadButtons(widget.chat.welcome_message)
        }
        if (widget.chat.reload_message) {
          this.$refs.buttonMessageReload.loadButtons(widget.chat.reload_message)
        }
      })
    },
    restoreDefault() {
      const { domain } = this.widget
      this.areYouSureRestoreDefault(restore => {
        if (restore && this.defaultWidget) {
          let w = JSON.parse(JSON.stringify(this.defaultWidget))
          if (
            !w.presentation.padding ||
            typeof w.presentation.padding !== 'object'
          ) {
            w.presentation.padding = {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }
          }
          this.widget = w
          this.setCommon(this.widget)
          this.execSave(false, true, domain)
        } else if (restore) {
          this.loadDefault()
          this.execSave(false, true, domain)
        }
      })
    },
    resetBtn() {
      this.messageBtnKey++
    },
    start() {
      this.widget = WidgetService.getDefault(this.lang, this.languageSelected)
      //this.settingsWidgetByDomain('test.com')
      this.widgetByBotIdNew().catch(e => {
        /** Error recognition */
        console.error(e)
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          title: 'Oops!',
          text: e.message,
          color: 'danger'
        })
      })
    },
    async saveWidgetLinkKonecta(domain, widgetFinal) {
      const _id = this.configDomainBot._id
      let toReturn = null
      if (_id) {
        toReturn = await WidgetService.updateWidgetBy_Id(
          _id,
          this.bot.id,
          domain
        )
      } else {
        toReturn = await WidgetService.createWidgetKona(
          this.bot.id,
          domain,
          widgetFinal
        )
      }
      return toReturn
    },
    async widgetByBotIdNew() {
      this.$vs.loading()
      await this.settingsWidgetByBotId(this.bot.id)
      if (!this.widget.domain) {
        this.loadDefault()
      }
      this.$vs.loading.close()
    },
    async widgetByBotId() {
      this.$vs.loading()
      const result = await WidgetService.getWidgetByBotId({
        botId: this.bot.id
      })
      if (result) {
        const { data } = result
        if (data && data.length) {
          this.configDomainBot = data[0]
          this.widget.domain = data[0].domain
          await this.settingsWidgetByDomain(data[0].domain)
        }
      }
      if (!this.widget.domain) {
        this.loadDefault()
      }
      this.$vs.loading.close()
    },
    async settingsWidgetByBotId(botId, recursive = 0) {
      const result = await WidgetService.getWidgetByBotIdFaunaMiddleware({
        botId
      }).catch(e => {
        if (e.response && e.response.status === 404) {
          // will create the widget config
        } else {
          const res = e.response
          if (res && res.data) {
            if (res.data.name == 'NotFound') {
              //
            } else {
              throw new Error(res.data.message)
            }
          } else {
            throw new Error(e.message)
          }
          return null
        }
      })
      if (
        !result ||
        !result.data ||
        !result.data.data ||
        !result.data.data.botId
      ) {
        if (recursive > 1) {
          console.error('recursive call', result)
          return
        }
        const defaultWidget = WidgetService.getDefault(
          this.lang,
          this.languageSelected
        )
        const defaultDomain = this.getDefaultDomainWidget(this.bot.id)
        const domain =
          defaultWidget && defaultWidget.domain
            ? defaultWidget.domain
            : defaultDomain
        defaultWidget.domain = domain
        defaultWidget.botId = botId

        const widgetFinal = this.finalData(defaultWidget)
        if (!result || !result.data || !result.data.data) {
          await this.createWidgetFauna(widgetFinal)
        } else {
          await WidgetService.updateWidgetFauna(this.widgetFaunaId, widgetFinal)
        }
        return this.settingsWidgetByBotId(botId, recursive + 1)
      }
      if (result && result.data) {
        this.widgetFaunaId = result.data.ref['@ref'].id
        const botIdFromSettings = result.data.data.botId
        if (botIdFromSettings && botIdFromSettings !== this.bot.id) {
          this.$vs.notify({
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
          return
        }
        const widgetProcessed = this.readDeprecated(result.data.data)
        this.widget = widgetProcessed
      } else {
        this.widget.botId = this.bot.id + '' // assign bot id
      }
      return result
    },
    async settingsWidgetByDomain(domain) {
      const result = await WidgetService.getWidgetByDomainFauna({
        domain
      }).catch(e => {
        const res = e.response
        if (res && res.data) {
          if (res.data.name == 'NotFound') {
            //this.newWidget = true
          } else {
            throw new Error(res.data.message)
          }
        } else {
          throw new Error(e.message)
        }
        return null
      })
      if (result && result.data) {
        this.widgetFaunaId = result.data.ref['@ref'].id
        const botIdFromSettings = result.data.data.botId
        if (botIdFromSettings && botIdFromSettings !== this.bot.id) {
          this.$vs.notify({
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
          return
        }
        const widgetProcessed = this.readDeprecated(result.data.data)
        this.widget = widgetProcessed
      } else {
        this.widget.botId = this.bot.id + '' // assign bot id
      }
      return result
    },
    ...mapActions(['getCompanySettings']),
    getFile(url) {
      if (!url || typeof url !== 'string') return null
      /** Get file for MediaPreview */
      return { name: '', fileData: '', fileUrl: url, fileFormat: 'jpg' }
    },
    isValidUrl(string) {
      try {
        new URL(string)
      } catch (_) {
        return false
      }

      return true
    },
    cancel() {
      this.SET_WIDGET_SETTINGS_OPEN(false);
    },
    chkPreItem(dW, pre, newWidget, itemName, hasDesktop = false) {
      if (
        pre[itemName] &&
        typeof pre[itemName] === 'object' &&
        (!hasDesktop || pre[itemName].desktop)
      ) {
        newWidget.presentation[itemName] = pre[itemName]
      } else {
        newWidget.presentation[itemName] = dW.presentation[itemName]
      }
    },
    readDeprecated(oW) {
      if (oW.default) {
        this.defaultWidget = oW.default
      }
      const defaultColorBot = {
        text: '#000',
        bg: '#f4f7f9',
        link: '#000'
      }
      const defaultColorUser = {
        text: '#ffffff',
        link: '#ffffff'
      }

      const defaultHeader = {
        title: 'Asistente Virtual'
      }

      const userMessage = {
        bg:
          oW.userMessage && oW.userMessage.bg
            ? oW.userMessage.bg
            : oW.primaryColor,
        // Color inverted in User
        text:
          oW.userMessage && oW.userMessage.text
            ? oW.userMessage.text
            : defaultColorUser.text,
        link:
          oW.userMessage && oW.userMessage.link
            ? oW.userMessage.link
            : defaultColorUser.link
      }

      const newWidget = {
        messageSize:
          oW.konectaWidget && oW.konectaWidget.messageSize
            ? oW.konectaWidget.messageSize
            : 'MEDIUM',
        header: {
          // OK Header Settings
          title: oW.header ? oW.header.title : defaultHeader.title,
          subtitle: oW.header ? oW.header.subtitle : '',
          image: oW.logoHeader,
          color: {
            text:
              oW.header && oW.header.color && oW.header.color.text
                ? oW.header.color.text
                : '#ffffff',
            bg:
              oW.header && oW.header.color && oW.header.color.bg
                ? oW.header.color.bg
                : oW.primaryColor
          }
        },
        domain: oW.domain, // OK Not in settings.json
        botId: oW.botId,
        url: oW.baseUrl,
        presentation: {
          size:
            oW.konectaWidget &&
            oW.konectaWidget.presentation &&
            oW.konectaWidget.presentation.size
              ? oW.konectaWidget.presentation.size
              : 'SMALL',
          // BUBBLE OK
          text: oW.konectaWeb.bubble_content.principal_text,
          image: oW.konectaWeb.bubble_content.isotipo_img,
          color: oW.konectaWeb.bubble_content.color,
          textColor: oW.konectaWeb.bubble_content.textColor
            ? oW.konectaWeb.bubble_content.textColor
            : '#ffffff',
          backgroundImg: oW.konectaWeb.backgroundImg
            ? oW.konectaWeb.backgroundImg
            : ''
        },
        disableAvatarUser: oW.konectaWidget
          ? oW.konectaWidget.disableAvatarUser === true
          : false,
        chat: {
          message_placeholder: oW.message_placeholder,
          datemessage_placeholder: oW.datemessage_placeholder,
          noLocationPermissionMessage: oW.noLocationPermissionMessage,
          size:
            oW.konectaWidget &&
            oW.konectaWidget.chat &&
            oW.konectaWidget.chat.size
              ? oW.konectaWidget.chat.size
              : 'MEDIUM',
          startMinimized: oW.konectaWidget
            ? oW.konectaWidget.chat.startMinimized === true
            : false,
          enableAudioMessages: oW.konectaWidget
            ? oW.konectaWidget.chat.enableAudioMessages === true
            : false,
          disableEmojis: oW.konectaWidget
            ? oW.konectaWidget.chat.disableEmojis === true
            : false,
          enableFiles: oW.konectaWidget
            ? oW.konectaWidget.chat.enableFiles === true
            : false,
          reaction: oW.konectaWidget ? oW.konectaWidget.chat.reaction : false, // OK
          fontFamily:
            oW.konectaWidget && oW.konectaWidget.chat.fontFamily
              ? oW.konectaWidget.chat.fontFamily
              : 'Roboto', // OK
          botLogo: oW.icons.chat_agent_image, // OK
          userLogo: oW.icons.chat_user_image, // OK
          color: oW.primaryColor ? oW.primaryColor : '#000000', // COLOR BACKGROUND HEADER
          textColor: oW.textColor ? oW.primaryColor : '#000000',
          welcome_message: oW.welcome_message,
          botMessage: {
            bg:
              oW.botMessage && oW.botMessage.bg
                ? oW.botMessage.bg
                : defaultColorBot.bg,
            text:
              oW.botMessage && oW.botMessage.text
                ? oW.botMessage.text
                : defaultColorBot.text,
            link:
              oW.botMessage && oW.botMessage.link
                ? oW.botMessage.link
                : defaultColorBot.link
          },
          buttons: {
            bg:
              oW.konectaWidget &&
              oW.konectaWidget.chat &&
              oW.konectaWidget.chat.buttons
                ? oW.konectaWidget.chat.buttons.bg
                : userMessage.bg,
            // Color inverted in User
            text:
              oW.konectaWidget &&
              oW.konectaWidget.chat &&
              oW.konectaWidget.chat.buttons
                ? oW.konectaWidget.chat.buttons.text
                : userMessage.text,
            border:
              oW.konectaWidget &&
              oW.konectaWidget.chat &&
              oW.konectaWidget.chat.buttons &&
              oW.konectaWidget.chat.buttons.border
                ? oW.konectaWidget.chat.buttons.border
                : userMessage.bg
          },
          userMessage,
          reload_message: oW.reload_message ? oW.reload_message : null,
          startMessage: oW.startMessage === true
        },
        features: {
          //"brandText": "Powered by Konecta®",
          uploadFile: oW.chat_controls.use_files,
          reloadChat: oW.konectaWeb.bubble_content.hasReload === true,
          historyChat: oW.fingerPrint === true,
          use_emoji: oW.chat_controls.use_emoji,
          use_audio: oW.chat_controls.use_audio
        },
        chatLink: {
          text: oW.chatLink.text,
          link: oW.chatLink.link,
          show: oW.chatLink.show
        }
      }
      let pre = {}
      const dW = WidgetService.getDefault(this.lang, this.languageSelected)
      if (oW.konectaWidget) {
        const konectaWidget = oW.konectaWidget
        if (konectaWidget.presentation) {
          pre = oW.konectaWidget.presentation
        }
        if (konectaWidget.fontSizes) {
          newWidget.fontSizes = { ...konectaWidget.fontSizes }
        }
      }
      if (!newWidget.fontSizes) {
        newWidget.fontSizes = { ...dW.fontSizes }
      }

      this.chkPreItem(dW, pre, newWidget, 'padding')
      this.chkPreItem(dW, pre, newWidget, 'borderRadius', true)
      this.chkPreItem(dW, pre, newWidget, 'width')
      this.chkPreItem(dW, pre, newWidget, 'height')
      this.chkPreItem(dW, pre, newWidget, 'position')

      this.setCommon(newWidget)
      return newWidget
    },
    async saveWidgetFauna(widgetSettings) {
      let result
      if (this.widgetFaunaId) {
        result = await WidgetService.updateWidgetFauna(
          this.widgetFaunaId,
          widgetSettings
        )
      } else {
        result = await this.createWidgetFauna(widgetSettings)
      }
      return result
    },
    saveDefault(cb) {
      if (this.defaultWidget) {
        if (this.widget.chat.reload_message === null)
          delete this.widget.chat.reload_message
        if (this.defaultWidget.chat.reload_message === null)
          delete this.defaultWidget.chat.reload_message
        const areEqual = isEqual(
          JSON.parse(JSON.stringify(this.defaultWidget)),
          JSON.parse(JSON.stringify(this.widget))
        )
        if (areEqual) {
          return cb(false)
        }
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title:
          this.lang.treeView.tree.confirmation.title[this.languageSelected],
        text: this.lang.botMaker.addBot.widget.defaultConfig.text[
          this.languageSelected
        ],
        acceptText:
          this.lang.treeView.tree.confirmation.acceptText[
            this.languageSelected
          ],
        cancelText:
          this.lang.treeView.tree.confirmation.cancelText[
            this.languageSelected
          ],
        accept: () => {
          cb(true)
        },
        cancel: () => {
          cb(false)
        }
      })
    },
    confirmSaveDefault(cb) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title:
          this.lang.treeView.tree.confirmation.title[this.languageSelected],
        text: this.lang.botMaker.addBot.widget.defaultConfig.textConfirm[
          this.languageSelected
        ],
        acceptText:
          this.lang.treeView.tree.confirmation.acceptText[
            this.languageSelected
          ],
        cancelText:
          this.lang.treeView.tree.confirmation.cancelText[
            this.languageSelected
          ],
        accept: () => {
          cb(true)
        },
        cancel: () => {
          cb(false)
        }
      })
    },
    async execSave(saveAsDefault, confirmChanges, _domain) {
      if (!confirmChanges) return
      let widget = this.widget
      let hasErrors = false
      const domain = _domain || widget.domain
      widget.domain = domain
      if (!domain) {
        this.error.domain = true
        hasErrors = true
      }

      if (hasErrors) {
        this.$refs.widget_settings_content.scrollTop = 0
        return
      }

      /** Start Loading Images */
      this.$vs.loading()

      try {
        if (
          widget.presentation.image &&
          typeof widget.presentation.image !== 'string'
        ) {
          widget.presentation.image =
            backend +
            (await this.saveFile(widget.presentation.image, this.bot.id))
        }

        if (
          widget.presentation.backgroundImg &&
          typeof widget.presentation.backgroundImg !== 'string'
        ) {
          widget.presentation.backgroundImg =
            backend +
            (await this.saveFile(
              widget.presentation.backgroundImg,
              this.bot.id
            ))
        }

        if (widget.chat.botLogo && typeof widget.chat.botLogo !== 'string') {
          widget.chat.botLogo =
            backend + (await this.saveFile(widget.chat.botLogo, this.bot.id))
        }

        if (widget.chat.userLogo && typeof widget.chat.userLogo !== 'string') {
          widget.chat.userLogo =
            backend + (await this.saveFile(widget.chat.userLogo, this.bot.id))
        }

        if (widget.header.image && typeof widget.header.image !== 'string') {
          widget.header.image =
            backend + (await this.saveFile(widget.header.image, this.bot.id))
        }

        if (!widget.botId) {
          widget.botId = this.bot.id
        }

        const widgetFinal = this.finalData(widget)
        if (saveAsDefault) {
          widgetFinal.default = widget
          this.defaultWidget = JSON.parse(JSON.stringify(widget))
        }

        // TODO: Future idea: remove `/fauna_widget/middleware`, using only `/fauna_widget`. Test if it works.
        await this.saveWidgetLinkKonecta(domain, widgetFinal)

        const resultFauna = await this.saveWidgetFauna(widgetFinal)
        this.widgetFaunaId = resultFauna.data.ref['@ref'].id

        this.$vs.notify({
          color: 'success',
          title:
            this.lang.botMaker.addBot.widget.success.title[
              this.languageSelected
            ],
          text: this.lang.botMaker.addBot.widget.success.text[
            this.languageSelected
          ]
        })
      } catch (e) {
        this.$vs.loading.close()
        this.$vs.notify({
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
      }
      this.$nextTick(() => {
        this.$vs.loading.close()
      })
    },
    async save() {
      this.saveDefault(async saveAsDefault => {
        this.confirmSaveDefault(async confirmChanges => {
          this.execSave(saveAsDefault, confirmChanges)
        })
      })
    },
    font_change(newFont) {
      document.querySelector('.dropdown-font-name').textContent = newFont.family
      document.querySelector('.dropdown-font-name').classList =
        'dropdown-font-name font-' +
        newFont.family.toLowerCase().replace(' ', '-')
      this.widget.chat.fontFamily = newFont.family
    },
    onTextButtonsChangeReload(newText) {
      this.widget.chat.reload_message = {
        text: [newText.buttonsText],
        context: {
          values: newText.buttons
            .filter(function (buttonText) {
              return buttonText !== '' && buttonText !== null
            })
            .map(function (buttonText) {
              return {
                text: buttonText,
                disabled: false
              }
            }),
          control: 'buttons'
        }
      }
    },
    onTextButtonsChange(newText) {
      this.widget.chat.welcome_message = {
        text: [newText.buttonsText],
        context: {
          values: newText.buttons
            .filter(function (buttonText) {
              return buttonText !== '' && buttonText !== null
            })
            .map(function (buttonText) {
              return {
                text: buttonText,
                disabled: false
              }
            }),
          control: 'buttons'
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    updateFile(widgetItem, file) {
      const fW = {
        'widget.presentation.backgroundImg': file => {
          this.widget.presentation.backgroundImg = file
        },
        'widget.presentation.image': file => {
          this.widget.presentation.image = file
        },
        'widget.header.image': file => {
          this.widget.header.image = file
        },
        'widget.chat.botLogo': file => {
          this.widget.chat.botLogo = file
        },
        'widget.chat.userLogo': file => {
          this.widget.chat.userLogo = file
        }
      }
      const fF = fW[widgetItem]
      fF(file)
    },
    async saveFile(file, botId) {
      const fileData = new FormData()
      fileData.append('file', file)
      return BotMakerService.fileUpload(fileData, botId).then(response => {
        return response.data.url
      })
    },
    copyMobileVersionUrlSuccess() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.success[this.languageSelected],
        color: 'success'
      })
    },
    copyMobileVersionUrlError() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.error[this.languageSelected],
        color: 'danger'
      })
    }
  }
}
</script>

<style lang="scss">

.vs-tooltip {
  z-index: 55000 !important;
}
.vs-con-input, .font-picker{
  margin-top: 4px;
}

.fontSizes_input {
  .vs-input-number-container {
    position: relative;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    label {
      top: -22px;
      position: absolute;
      font-size: 14px;
    }
    .vs-input-number {
      margin: 2px 0 0;
    }
    .vs-input-number-container-text {
      position: relative;
      margin-top: 15px;
      label {
        top: -20px;
      }
    }
    .vs-input-number-container-button {
      position: relative;
      margin-top: 90px;
      label {
        top: -20px;
      }
    }
  }
  &_buttons {

    .vs-input-number-container {
      margin-top: 32px;
    }
    display: flex;
    .buttons-message {
      width: 250px;
      margin-top: .5rem;
    }
  }
  display: flex;
}
.fontSettings_widget {
  box-shadow: 1px 1px 10px #00000045;
  max-width: 90%;
  margin: auto;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
  h3 {
    padding-left: 15px;
    font-weight: 500;
    font-size: 16px;
  }
  .vs-input-number {
    width: 100px;
  }
  .form-group {
    flex-direction: column;
    margin-bottom: 0;
  }
  .form-group-label {
    width: 100% !important;
  }
  h1 {
    padding-left: 30px;
    font-weight: bold;
    font-size: 20px;
  }
  .vs-col.main-col-widget {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.widget_settings {
  .vs-input-number {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, .2);
    background: white;
    margin: 5px;
    &--button-less, &--button-plus{
      height: 40px;
      border: 1px solid rgba(0, 0, 0, .2);
      background: #f5f5f5 !important;
        
      &.limit{
        opacity: 1 !important;

        & .vs-icon{
          color:rgba(0,0,0,.5) !important;
        }
      }
    }
    &--button-less{
      border-radius: 10px 0 0 10px;
    }
    &--button-plus{
      border-radius: 0 10px 10px 0;
    }
  }

  .multiselect__tags, .vs-input--input.normal, .vs-input-number, .font-picker, .dropdown-button{
    border-radius: 10px;
    height: 40px;
  }
  .font-picker{
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: none;
    margin-bottom: 16px;
    .dropdown-button, .dropdown-button.expanded, .dropdown-button:hover, .dropdown-button:focus{
      background: transparent;
      height: 40px;
    }
    ul{
      background: white;
      margin-top: 2px;
      &.expanded{
        border: 1px solid rgba(0, 0, 0, .2);
      }
    }
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: .5rem;
  }
  .media-preview{
    margin-left: 24px;
  }
  .header-bot{
    .form-group{
      margin-top: 1rem;
    }
  }

  &_advance {
    margin-bottom: 20px;
    box-shadow: 0px 15px 10px -15px rgb(0, 0, 0, 0.04);
    
    .enable_auto_btn {
      margin-left: 7px;
    }
    .restore_btn {
      margin-bottom: 1rem;
    }
    h1 {
      padding-left: 30px;
      font-weight: bold;
      font-size: 20px;
    }
    .vs-inputx {
      width: 100px;
      height: 40px;
      text-align: center;
      margin: 5px 0;

      &.normal{
        border-radius: 10px;
        width: 110px;
      }
    }
    .vs-col {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .vs-con-input-label{
      width: inherit;
    }
    h3 {
      color:rgba(0,0,0,.5) !important;
    }
  }
  .vs-icon.vs-checkbox--icon.material-icons{
    color:white !important;
    font-weight: 700;
  }
  &_padding_row, &_advance{
    margin-top: .5rem;
  }
  &_welcomeMessage {
    display: flex;
    .buttons-message {
      justify-content: start;
    }
  }
  &_content {
    overflow: auto;

    .icon-header{
      i.vs-icon{
       font-size: 24px;
      }
    }
  }
  &_konaUpload {
    .konaUpload-image {
      flex-direction: row-reverse;

      .kona-upload{
        display:-webkit-box;
        flex-direction: inherit;
        width: 100%;
        .kona-upload__input{
          width: 100%;
          .vs-con-input-label{
            width: auto;
          }
        }
      }
    }
    .media-preview__preview.frame {
      margin: 0;
      max-width: 60px;
    }
  }
  &.pt-3.img-drop-shadow{
    width: 100%;
    .vs-icon{
      color: rgba(var(--vs-primary), 1);
    }
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    padding-bottom: 0;
    .vs-button {
      margin-right: 15px;
    }
  }
  .container_colors {
    
    input[type="color" i] {
      height: 40px;
      width: 36px;
      border: none;
      background: transparent;
      
    }
  } 
}
.img-drop-shadow img {
  filter: drop-shadow(3px 2px 2px);
}
.copyTextArea {
  display: flex;
}
.copyButtonScript {
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyScriptButton {
  max-width: 50px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .vs-button-text {
    width: 20px !important;
  }
}
.copyTextArea .language-html {
  padding: 10px 10px 10px 20px;
  word-break: break-word;
  flex-grow: 1;
}
.language-html .tag {
  &:hover {
    padding: 0;
  }
  padding: 0;
  border: none;
  float: initial;
  background: transparent;
  margin: 0;
}
.colors-table {
  tr {
    td {
      padding: 10px 0;
    }
  }
}
.tab-bottom {
  border-bottom: 1px solid red;
}

.widget_settings-row{
  height: 70vh;
  .vs-col{
    padding: 0;
  }
  
  .vs-col:last-child{
    .widget-settings-section.expanded{
      margin-left: 12px;
    }
  }
  .vs-col:first-child{
    .widget-settings-section.expanded{
      margin-right: 12px;
    }
  }
}
.widget-settings-section.expanded {
  height: 100% !important;
}
.widget-settings-section {
  margin: 10px 0;
  overflow: auto;
  padding: 0 16px;
  background-color: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 68vh !important;
  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 21px;
    // margin-top: 10px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }
  .vs-collapse {
    // padding: 0 10px;
    background-color: white;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    &.shadow .open-item {
      box-shadow: none;
      margin: 0;
    }

    .vs-collapse-item {
      border-bottom: none;
      .con-content--item {
        opacity: 0;
        .vs-tabs--ul .vs-tabs--li span{
          font-family: Montserrat, sans-serif;
        }
      }
      &--header{
        margin: 1rem 0;
        padding-left: .5rem;
      }
      &.open-item {
        .vs-collapse-item--content {
          overflow: auto;
          max-height: 100% !important;
        }
        .con-content--item {
          opacity: 1;
          padding: 0 .5rem .5rem;
        }
        .vs-collapse-item--header{
          margin-bottom: -1rem;
        }
      }
    }
  }
}
.w-100{
  width: 100% !important;
}
.align-bottom {
  align-self: flex-end;
}
.overflow-visible{
  overflow: visible;
}
@media only screen and (max-width: 1150px) {
  .pl-md-0{
    padding-left: 0 !important;
  }
  .col-md-50 {
    width: 50% !important;
  }
  .col-md-100 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .col-sm-50 {
    width: 50% !important;
  }
  .col-sm-100 {
    width: 100% !important;
  }
}
</style>
