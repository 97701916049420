<template>
  <div>
  <div id="create-edit-bot" v-show="!isWidgetSettingsOpen">
    <vs-row>
      <vs-col>
        <vs-card>
          <div slot="header">
            <vs-row vs-align="center">
              <vs-col vs-w="8" class="flex">
                <h1 v-if="isEditing">
                  {{ lang.botMaker.editBot.title[languageSelected] }}
                </h1>
                <h1 v-else>
                  {{ lang.botMaker.addBot.title[languageSelected] }}
                </h1>
                <!-- <vs-button
                  class="ml-4"
                  size="small"
                  type="flat"
                  @click="$tours['konectaTour'].start()"
                >
                  {{ lang.konectaTour.takeTour[languageSelected] }}
                </vs-button> -->
              </vs-col>
              <vs-col vs-w="4" vs-type="flex" vs-justify="flex-end" v-if="!isReadOnly">
                <vs-button
                  id="save-btn"
                  color="primary"
                  type="flat"
                  icon="save"
                  size="large"
                  @click="createBot"
                ></vs-button>

                <vs-button
                  color="primary"
                  type="flat"
                  icon="edit"
                  size="large"
                  @click="navigateToEditBot"
                  :disabled="isEditBtnDisabled"
                ></vs-button>
                <vs-button
                  color="primary"
                  type="flat"
                  icon-pack="feather"
                  icon="icon-trash"
                  size="large"
                  @click="deleteBot"
                  :disabled="isDeleteBtnDisabled"
                ></vs-button>
              </vs-col>
            </vs-row>
          </div>

          <div class="create-edit-bot-wrapper" v-if="botLoaded">
            <KonaTabs>
              <!-- general -->
              <KonaTab
                :name="lang.botMaker.addBot.tabs.general[languageSelected]"
                icon="settings"
                :selected="true"
                id="general"
              >
                <BasicSetting
                  v-if="isAuthAux(`AddBot.generalSettings isEditing:${isEditing}`, false) || session.user.roles.canEditBot.generalSettings"
                />

                <GeneralEngineSetting
                  v-if="isAuthAux(`AddBot.isEngineSettingsEnabled isEditing:${isEditing}`, false) || isEngineSettingsEnabled"
                  :isEditing="isEditing"
                  :noIntentActionSelected="onActionSelect"
                />

                <SessionSettings
                  v-if="isAuthAux(`AddBot.isSessionSettingsEnabled isEditing:${isEditing}`, false) || isSessionSettingsEnabled"
                  :isEditing="isEditing"
                />

                <forking-table v-if="!bot.id" />

                <!-- <import-bot v-if="bot.id" /> -->

                <bot-time-zone
                  v-if="isAuthAux(`AddBot.botAvailability isEditing:${isEditing}`, false) || session.user.roles.canEditBot.botAvailability"
                  :isEditing="isEditing"
                />
              </KonaTab>

              <!-- engine -->
              <KonaTab
                :name="lang.botMaker.addBot.tabs.empathic[languageSelected]"
                icon="emoji_emotions"
              >
                <EmpathicEngineSettings :isEditing="isEditing" />
              </KonaTab>

              <!-- users -->
              <KonaTab
                id="users"
                :name="lang.botMaker.addBot.tabs.users[languageSelected]"
                icon="person_outline"
                v-if="true || isAuthAux(`AddBot.users isEditing:${isEditing}`, false) || session.user.roles.canEditBot.serviceUsers"
              >
                <users-administration
                  v-if="true || isAuthAux(`AddBot.users isEditing:${isEditing}`, false) || session.user.roles.canEditBot.serviceUsers"
                  :read-only="isReadOnly"
                />
              </KonaTab>

              <!-- availability -->
              <KonaTab
                id="availability"
                :name="lang.botMaker.addBot.tabs.availability[languageSelected]"
                icon="check_circle_outline"
                v-if="isAuthAux(`AddBot.botAvailability isEditing:${isEditing}`, false) || session.user.roles.canEditBot.botAvailability"
              >
                <bot-availability
                  v-if="isAuthAux(`AddBot.botAvailability isEditing:${isEditing}`, false) || session.user.roles.canEditBot.botAvailability"
                />
              </KonaTab>

              <!-- priorities -->
              <KonaTab
                :name="lang.botMaker.addBot.tabs.priorities[languageSelected]"
                icon="low_priority"
              >
                <PrioritiesSettings :isEditing="isEditing" :read-only="isReadOnly"/>
              </KonaTab>

              <!-- channels -->
              <KonaTab
                :name="lang.botMaker.addBot.tabs.channels[languageSelected]"
                icon="share"
                id="channels"
              >
                <BotChannels :read-only="isReadOnly"/>
              </KonaTab>
            </KonaTabs>

            <div class="footer">
              <vs-button
                color="danger"
                type="flat"
                :to="{ path: '/bot-maker/bots' }"
                >{{ lang.botMaker.cancel[languageSelected] }}</vs-button
              >
              <vs-button
                v-if="!isReadOnly"
                color="primary"
                type="filled"
                @click="createBot"
                data-cy="saveNewBot"
              >
                <span v-if="isEditing">
                  {{ lang.botMaker.editBot.saveBot[languageSelected] }}
                </span>
                <span v-else>
                  {{ lang.botMaker.addBot.startDevelop[languageSelected] }}
                </span>
              </vs-button>
            </div>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup
      :title="lang.botMaker.jump.title[languageSelected]"
      :active.sync="jumpPopupActive"
      icon-pack="feather"
      icon-close="icon-x"
      style="padding: 0px"
      @close="onCloseJumps"
    >
      <!-- <div v-if="dialogJump" class="jumpHeader">
        <p class="current-jump">
          <vs-icon color="warning" icon="warning"></vs-icon>
          {{ lang.botMaker.jump.current[languageSelected] }}
          <span>{{ dialogJump }}</span>
        </p>
        <vs-divider />
      </div> -->

      <KonaTree
        v-if="jumpPopupActive"
        :tpl="'basic'"
        :key="keyRefresh"
        :tree-data="treeData"
        :searchBar="true"
        :showJump="false"
        :halfcheck="false"
        :multiple="false"
        :radio="true"
        :canMove="false"
        :serachWidht="'w-1/2'"
        :marginTop="'mt-2'"
        @clickNode="clickNode"
        ref="konaTree"
      />

      <div class="jumpFooter">
        <vs-divider />
        <vs-row
          vs-align="center"
          vs-type="flex"
          vs-justify="space-around"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button @click="deleteJump" color="danger">
              <!-- :disabled="!jumpDialogId" -->
              {{ lang.botMaker.jump.remove.title[languageSelected] }}
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button @click="updateJump" :disabled="!jumpDialog">
              {{ lang.botMaker.jump.update.title[languageSelected] }}
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button @click="closeJumpPopup" type="border">
              {{ lang.botMaker.integrations.cancel[languageSelected] }}
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
  <WidgetSettings :read-only="isReadOnly"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import BasicSetting from './BasicSetting'
import UsersAdministration from './usersAdministration'
import GeneralEngineSetting from './GeneralEngineSetting'
import SessionSettings from './SessionSettings'
import BotTimeZone from './BotTimeZone'
import BotAvailability from './BotAvailability'
import ForkingTable from './Forking/ForkingTable'
// import ImportBot from './ImportBot/ImportBot'
import botTreeMixin from '@/mixins/botTreeMixin'
import BotMakerService from '../../../../services/botMaker.service'
import { generateBot } from '../../../../models/BotGenerator'
import widgetSettingsMixin from '@/mixins/widgetSettingsMixin.js'
import WidgetService from '@/services/fauna.widget.service.js'
import WidgetSettings from './BotWidgetSettings/WidgetSettings.vue'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  components: {
    BotTimeZone,
    BotAvailability,
    BasicSetting,
    GeneralEngineSetting,
    SessionSettings,
    UsersAdministration,
    ForkingTable,
    WidgetSettings,
    // ImportBot,
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTree: () => import('@/components/KonaTree.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    BotChannels: () => import('./bot-channels/BotChannels.vue'),
    EmpathicEngineSettings: () => import('./EmpathicEngineSettings.vue'),
    PrioritiesSettings: () => import('./PrioritiesSettings.vue')
  },
  data() {
    return {
      jumpPopupActive: false,
      lastJumpActionNoIntention: null,
      jumpDialog: null,
      keyRefresh: new Date().getTime(),
      botId: undefined,
      botLoaded: false,
      intervalId: undefined,
      showEditorNavBarOptions: false,
      newBot: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', [
      'userModel',
      'bot',
      'validations',
      'isWidgetSettingsOpen',
      'sectionReady',
      'usersByChannelMap',
      'hasTimeoutValidation',
      'hasNegativeSentimentOption',
      'botTimeoutMessageValidation',
      'negativeSentimentOption',
      'getBots'
    ]),
    ...mapGetters('integrations', ['integrations', 'botChannels']),
    ...mapGetters('botMaker', ['activeVersion', 'activeVersionData']),
    isEditing() {
      return !!this.botId
    },
    isDeleteBtnDisabled() {
      return !this.session.user.roles.canEditBot.deleteBot
    },
    isEditBtnDisabled() {
      return !this.isEditing && !this.session.user.roles.canAccess.botMaker
    },
    isEngineSettingsEnabled() {
      return (
        !this.bot.legacy && this.session.user.roles.canEditBot.engineSettings
      )
    },
    isSessionSettingsEnabled() {
      return this.session.user.roles.canEditBot.sessionSettings
    },
    fork() {
      return this.bot && this.bot.fork ? JSON.stringify(this.bot.fork) : null
    },
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true) && !this.isAuthAux('konecta.bots.add', true)
    }
  },
  watch: {
    jumpPopupActive(newVal) {
      if (newVal == true) {
        this.keyRefresh = new Date().getTime()
      }
    }
  },
  mixins: [botTreeMixin, widgetSettingsMixin, staffManagementMixin],
  methods: {
    ...mapMutations('bots', [
      'RESET_ADD_BOT',
      'SET_BOT',
      'SET_WIDGET_SETTINGS_OPEN',
      // 'SET_BOT_COPY',
      'RESET_SECTIONS_READY',
      'SET_SECTION_READY_BASED_ON_ROL',
      'RESET_TIMEOUT_VALIDATION',
      'RESET_NEGATIVE_OPTION'
    ]),
    ...mapMutations('botMaker', {
      UPDATE_BOT: 'SET_BOT',
      SET_VERSION: 'SET_VERSION'
    }),
    ...mapActions('credentials', ['GET_NLU']),
    ...mapActions('bots', [
      'SAVE_INTENTS',
      'GET_BOTS',
      'GET_VERSION_INTENTS',
      'DELETE_BOT',
      'SAVE_TIMEOUT_VALIDATION',
      'SAVE_NEGATIVE_OPTION',
      'GET_TIMEOUT_VALIDATION',
      'GET_NEGATIVE_OPTION',
      'VALIDATE_NEGATIVE_OPTION'
    ]),
    ...mapActions('integrations', ['CREATE_INTEGRATION', 'UPDATE_INTEGRATION']),
    ...mapMutations('integrations', ['RESET_BOT_CHANNELS', 'START_STORE']),
    navigateToEditBot() {
      let botName = this.bot.service.name
      let botId = this.bot.id
      let serviceId = this.bot.service.id
      this.SET_BOT(this.bot)

      this.$router.push({
        path: '/bot-maker/bot/' + botName,
        query: {
          botId: botId,
          serviceId: serviceId,
          version: this.activeVersion
        }
      })
    },
    async onActionSelect() {
      await this.initTree()
      this.jumpPopupActive = true
    },
    deleteBot() {
      let self = this
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.addBot.deleteBotPrompt.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.addBot.deleteBotPrompt.message[
          this.languageSelected
        ],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: () => {
          if (self.bot.id) {
            self.$vs.loading()
            this.DELETE_BOT(self.bot.id)
              .then(() => {
                self.$router.push({ path: '/bot-maker/bots' })
              })
              .catch(() => {
                self.$vs.notify({
                  text: this.lang.botMaker.error[this.languageSelected],
                  color: 'danger'
                })
              })
              .finally(() => {
                self.$vs.loading.close()
              })
          } else {
            self.$router.push({ path: '/bot-maker/bots' })
          }
        }
      })
    },
    clickNode(node) {
      this.jumpDialog = node[0] || null
    },
    onCloseJumps() {
      this.$refs.konaTree.reset()
      if (!this.bot.noIntentAction.args[0]) {
        this.bot.noIntentAction = {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: [this.bot.noIntentMessage]
        }
      }
    },
    deleteJump() {
      /* deleteJump */
    },
    closeJumpPopup() {
      // when tree popup closed without saving, restore last selection
      if (!this.bot.noIntentAction.args[0]) {
        this.bot.noIntentAction = {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: [this.bot.noIntentMessage]
        }
      }
      this.jumpPopupActive = false
    },
    updateJump() {
      this.bot.noIntentAction = {
        name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
          this.languageSelected
        ],
        key: 'jump',
        args: [this.jumpDialog.id]
      }
      this.jumpDialog = null
      this.jumpPopupActive = false
    },
    isValidBotName() {
      return !this.getBots.some(
        b => b.service.name === this.bot._botService.name
      )
    },
    createBot() {
      if (!this.isEditing && !this.isValidBotName()) {
        this.$vs.notify({
          text: this.lang.botMaker.errorName[this.languageSelected],
          color: 'danger'
        })
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        return
      }
      this.bot.validate(this.validations)
      this.VALIDATE_NEGATIVE_OPTION()
      if (
        this.bot.isValid() &&
        !this.validations.negativeSentiment.trigger.sentimentLessThan &&
        !this.validations.negativeSentiment.message &&
        !this.validations.negativeSentiment.trigger.cant &&
        !this.validations.notDialogDetected
      ) {
        if (this.bot.id) {
          this.updateBot()
        } else {
          if (!this.isValidBotName()) {
            this.$vs.notify({
              text: this.lang.botMaker.errorName[this.languageSelected],
              color: 'danger'
            })
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            return
          }
          this.saveNewBot()
        }
      } else {
        this.$log.error(this.validations)
        this.$vs.notify({
          text: this.lang.botMaker.formWithError[this.languageSelected],
          color: 'danger'
        })
      }
    },
    async createWidget(botId, culture) {
      await this.createDefaultWidget(botId, culture)
    },
    async saveNewBot() {
      let botName = this.bot.service.name
      this.$vs.loading()
      try {
        const botToSave = this.bot.generateNewBotToSave(
          this.session.user.roles,
          this.usersByChannelMap,
          this.useStaffManagement
        )

        botToSave.credential = this.bot.credential

        // Create the bot
        let saveBotResponse = await BotMakerService.createBot(botToSave)

        this.bot.id = saveBotResponse.data._id
        this.bot.service.id = saveBotResponse.data.service

        // Create version
        let versionCreationResponse = await BotMakerService.createVersion(
          this.bot.service.id,
          botToSave.credential,
          this.bot.fork
        )
        this.bot.activeVersion = versionCreationResponse.data._id

        if (!this.bot.legacy && !this.bot.fork) {
          await this.SAVE_INTENTS(this.bot.intents)
        }

        await this.SAVE_TIMEOUT_VALIDATION(this.bot.id)
        await this.SAVE_NEGATIVE_OPTION(this.bot.id)
        // await this.saveChannels()

        await this.createWidget(this.bot.id, this.bot.nlu.culture)

        // TODO: Future idea: remove `/fauna_widget/middleware`, using only `/fauna_widget`. Test if it works.
        const defaultWidget = WidgetService.getDefault(
          this.lang,
          this.languageSelected
        )
        const defaultDomain = this.getDefaultDomainWidget(this.bot.id)
        const domain =
          defaultWidget && defaultWidget.domain
            ? defaultWidget.domain
            : defaultDomain
        defaultWidget.domain = domain
        await WidgetService.createWidgetKona(this.bot.id, domain, defaultWidget)

        this.$router.push({
          path: `/bot-maker/bot/${botName}`,
          query: {
            botId: this.bot.id,
            serviceId: this.bot.service.id
          }
        })
      } catch (error) {
        if (this.bot.id) {
          await BotMakerService.deleteBot(this.bot.id)
        }
        this.$notify.error(
          this.lang.botMaker.addBot.notifications.couldNotSaveBot.title[
            this.languageSelected
          ],
          this.lang.botMaker.addBot.notifications.couldNotSaveBot.text[
            this.languageSelected
          ]
        )
      }

      this.$vs.loading.close()
    },
    async updateBot() {
      this.$vs.loading()

      try {
        if (!this.bot.legacy) {
          await this.bot.updateIntentsBotResponse()
        }

        await this.bot
          .updateBot(this.session.user.roles, this.usersByChannelMap, this.useStaffManagement)
          .then(response => {
            this.UPDATE_BOT(response.data)
          })
        await this.SAVE_TIMEOUT_VALIDATION(this.bot.id)
        await this.SAVE_NEGATIVE_OPTION(this.bot.id)
        // await this.saveChannels()

        this.navigateToEditBot()
      } catch (error) {
        this.$log.error(error)
        this.$notify.error(
          this.lang.botMaker.addBot.notifications.couldNotSaveBot.title[
            this.languageSelected
          ],
          this.lang.botMaker.addBot.notifications.couldNotSaveBot.text[
            this.languageSelected
          ]
        )
      }

      this.$vs.loading.close()
    },
    // saveChannels() {
    //   this.botChannels.forEach(channel => {
    //     const integration = this.integrations.find(
    //       i => i.knownType === channel.type
    //     )

    //     if (integration) {
    //       this.updateChannel(channel)
    //     } else if (channel.active) {
    //       this.createChannel(channel)
    //     }
    //   })
    // },
    // async createChannel(channel) {
    //   try {
    //     const payload = {
    //       service: this.bot.service.id,
    //       type: channel.type,
    //       active: channel.active,
    //       config: {}
    //     }
    //     await this.CREATE_INTEGRATION(payload)
    //   } catch (error) {
    //     this.undoChannel(channel)
    //   }
    // },
    // async updateChannel(channel) {
    //   try {
    //     const payload = {
    //       active: channel.active
    //     }

    //     await this.UPDATE_INTEGRATION({
    //       params: payload,
    //       id: channel._id
    //     })
    //   } catch (error) {
    //     this.undoChannel(channel)
    //   }
    // },
    undoChannel(channel) {
      // revert channel status update
      channel.active = !channel.active

      this.$vs.notify({
        title: this.lang.botMaker.errorTitle[this.languageSelected],
        text: this.lang.botMaker.addBot.botChannels.messages.errors
          .createIntegration[this.languageSelected],
        color: 'danger'
      })
    },
    stopInterval() {
      clearInterval(this.intervalId)
    },
    closeLoading() {
      this.$vs.loading.close()
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    this.RESET_ADD_BOT(this.languageSelected)
    this.RESET_SECTIONS_READY()
    this.RESET_TIMEOUT_VALIDATION()
    this.RESET_NEGATIVE_OPTION()
    await this.GET_NLU()

    const BOT_ID = this.$route.query.botId
    if (BOT_ID) {
      this.SET_SECTION_READY_BASED_ON_ROL(this.session.user.roles)
      this.botId = BOT_ID
      this.$vs.loading()
      try {
        let botResponse = await BotMakerService.getBot(this.botId)
        let botGenerated = generateBot(botResponse.data)
        if (this.activeVersionData) {
          const { versionId, versionFork } = this.activeVersionData
          botGenerated.activeVersion = versionId
          botGenerated.fork = versionFork
        }
        this.SET_BOT(botGenerated)
        if (!this.isEngineSettingsEnabled) {
          this.sectionReady.engineSettings = true
        }
        this.UPDATE_BOT(this.bot)
        await this.GET_TIMEOUT_VALIDATION(BOT_ID)
        await this.GET_NEGATIVE_OPTION(BOT_ID)
        await this.GET_VERSION_INTENTS(botGenerated.activeVersion)
      } catch (error) {
        this.$log.error(error)
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected]
        })
      } finally {
        this.$vs.loading.close()
      }
      this.botLoaded = true
      // Check if all the sections are loaded
      let self = this
      this.intervalId = setInterval(() => {
        let allTrue = Object.keys(self.sectionReady).every(function(k) {
          return self.sectionReady[k] === true
        })
        if (allTrue) {
          self.stopInterval()
          self.closeLoading()
        }
      }, 1000)
      await this.GET_INTENTIONS()
      await this.GET_DIALOGS(undefined)
    } else {
      this.botLoaded = true
      this.newBot = true
      this.RESET_BOT_CHANNELS()
      //this.negativeSentimentOption.positive.actions[0].args[0] = this.lang.botMaker.addBot.engineSetting.negativeSentimentAction.firstMessage[this.languageSelected]
      this.START_STORE()
    }
    this.$set(this.bot, 'fork', null)
    this.lastJumpActionNoIntention = this.bot.noIntentAction
  }
}
</script>

<style lang="scss">
#create-edit-bot {
  .bot-channels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fafafa;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    .vs-button {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.vs-con-textarea {
  background-color: white;
  resize: none;
  margin-bottom: 0;
  &.error {
    border: 1px solid rgba(var(--vs-danger), 1);
  }
}
.fill {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.with-space-to-the-right {
  margin-right: 5px;
}
</style>
