<template>
  <add-bot-section
    :title="lang.botMaker.addBot.sessionSettings.title[languageSelected]"
  >
    <template slot="whole-w">
      <form-group
        class="settings-form-group"
        :label="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus0[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus0Tooltip[
            languageSelected
          ]
        "
      >
        <TimeLog v-model="bot.sessionTimeoutStatus0" />
      </form-group>
      <form-group
        class="settings-form-group"
        :label="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus1[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus1Tooltip[
            languageSelected
          ]
        "
      >
        <TimeLog v-model="bot.sessionTimeoutStatus1" />
      </form-group>
      <form-group
        class="settings-form-group"
        :label="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus2[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus2Tooltip[
            languageSelected
          ]
        "
      >
        <TimeLog v-model="bot.sessionTimeoutStatus2" />
      </form-group>
      <form-group
        class="settings-form-group"
        :label="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus3[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus3Tooltip[
            languageSelected
          ]
        "
      >
        <TimeLog v-model="bot.sessionTimeoutStatus3" />
      </form-group>
    </template>
  </add-bot-section>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AddBotSection from './Components/AddBotSection'
import FormGroup from './Components/FormGroup'

export default {
  name: 'engineSetting',
  props: {
    isEditing: Boolean
  },
  components: {
    AddBotSection,
    FormGroup,
    TimeLog: () => import('@/components/TimeLog.vue')
  },
  data() {
    return {
      nluIntentConfidence: [
        { text: 'high', value: 0.85 },
        { text: 'medium', value: 0.6 },
        { text: 'low', value: 0.25 }
      ]
    }
  },
  watch: {
    'bot.nlu.platform'(newVal) {
      if (newVal === 'rasa' || newVal === 'rasa_v2' || newVal === 'watson') {
        this.bot.intentConfidence = this.nluIntentConfidence[1].value
      } else if (newVal === 'luis') {
        this.bot.intentConfidence = this.nluIntentConfidence[2].value
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapGetters('credentials', ['nlu'])
  },
  methods: {
    ...mapMutations(['UPDATE_NEW_BOT_LANGUAGE']),
    ...mapMutations('bots', [
      'UPDATE_BOT_NO_INTENT_MESSAGE',
      'UPDATE_BOT_CONNECTION_ERROR_MESSAGE'
    ]),
    languageChanged() {
      const newBotLanguage = this.bot.nlu.culture.split('-')[0]
      this.UPDATE_NEW_BOT_LANGUAGE(newBotLanguage)
    }
  },
  mounted() {
    this.sectionReady.engineSettings = true
    this.languageChanged()
  }
}
</script>

<style lang="scss">
.settings-form-group {
  display: flex;
  align-items: center;
}
</style>
