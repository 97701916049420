<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <!-- general settings -->
    <add-bot-section :title="lang.botMaker.addBot.generalSettings.title[languageSelected]" class="add-bot-section">
      <template slot="whole-w">
        <!-- action when unknown intent -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.noIntentMessage[languageSelected]
          "
        >
          <div class="no-intent-action">
            <multiselect
              :key="keyRefresh"
              class="no-intent-action-key"
              :allow-empty="false"
              v-model="bot.noIntentAction"
              :options="getAvailableNoIntentActions()"
              label="name"
              track-by="key"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="lang.botMaker.botMessageValidation.form.answers.table.actionsPlaceholder[
                languageSelected
              ]"
              @input="onActionSelect()"
            />
            <vs-input
              :danger="validations.noIntentAction"
              :danger-text="
                lang.botMaker.addBot.engineSetting.noIntentMessageError[
                  languageSelected
                ]
              "
              class="w-full no-intent-action-value"
              v-if="bot.noIntentAction && bot.noIntentAction.key === 'responseText'"
              v-model="bot.noIntentAction.args[0]"
            />
            <vs-button
              v-if="
                bot.noIntentAction &&
                bot.noIntentAction.key === 'jump' &&
                bot.noIntentAction.args[0] !== ''
              "
              size="small"
              class="integration-settings-btn no-intent-action-value"
              icon="icon-settings"
              icon-pack="feather"
              @click="openSettings()"
            ></vs-button>
            <span
              class="w-full no-intent-action-value" 
              v-if="
                bot.noIntentAction &&
                bot.noIntentAction.key === 'jump' &&
                  bot.noIntentAction.args[0] !== ''
              "
            >
              {{
                  dialogsMap[bot.noIntentAction.args[0]] && dialogsMap[bot.noIntentAction.args[0]]
                  .name
              }}
            </span>
          </div>
        </new-form-group>

        <!-- NLU connection error message -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.connectionErrorMessage[
              languageSelected
            ]
          "
        >
          <vs-input
            class="fill"
            required
            v-model="bot.connectionErrorMessage"
            :danger="validations.connectionErrorMessage"
            :danger-text="
              lang.botMaker.addBot.engineSetting.connectionErrorMessageError[
                languageSelected
              ]
            "
          />
        </new-form-group>

        <!-- show suggestions -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.showSuggestions[languageSelected]
          "
          v-show="!bot.bypass"
        >
          <vx-slider v-model="bot.passiveChat" />
        </new-form-group>

        <!-- inactivity -->
        <div class="flex">
          <!-- max inactivity time -->
          <div style="min-width: 295px">
            <new-form-group :label="lang.botMaker.addBot.engineSetting.inactivityTime[languageSelected]">
              <vs-input
                type="number"
                required
                :min="0"
                v-model="bot.inactivityTime"
                :danger="validations.inactivityTime"
                :danger-text="
                  lang.botMaker.addBot.engineSetting.inactivityTimeError[
                    languageSelected
                  ]
                "
                @keypress="isDigit($event)"
              />
            </new-form-group>
          </div>

          <!-- message inactivity time -->
          <div class="ml-4 w-full">
            <new-form-group :label="lang.botMaker.addBot.engineSetting.inactivityMessage[languageSelected]">
              <vs-input
                class="w-full"
                required
                v-model="bot.inactivityMessage"
                data-cy="mjAfterinactivitytime"
                :danger="validations.inactivityMessage"
                :danger-text="
                  lang.botMaker.addBot.engineSetting.inactivityMessageError[
                    languageSelected
                  ]
                "
              />
            </new-form-group>
          </div>
        </div>

        <!-- question to user pending attention -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.timeoutAction[languageSelected]
          "
          :tooltip="
            lang.botMaker.addBot.engineSetting.timeoutActionTooltip[
              languageSelected
            ]
          "
        >
          <div class="flex">
            <vs-checkbox color="primary" class="timeout-action-checkbox" v-model="hasTimeout" @change="openTimeoutSettings">
              {{ 
                lang.botMaker.addBot.engineSetting.timeoutActionExecute[
                  languageSelected
                ]
              }}
            </vs-checkbox>
            <vs-button
              v-if="hasTimeoutValidation"
              type="flat"
              class="integration-settings-btn ml-4"
              icon="icon-settings"
              icon-pack="feather"
              @click="openTimeoutSettings()"
            />
            <span v-if="hasTimeoutValidation" class="w-full ml-4"> {{ botTimeoutMessageValidation.service.name }} </span>
          </div>
        </new-form-group>

        <!-- disambiguation -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.disambiguation.text[
              languageSelected
            ]
          "
          :tooltip="
            lang.botMaker.addBot.engineSetting.disambiguation.tooltip[
              languageSelected
            ]
          ">
          <div class="no-intent-action">
            <vs-checkbox color="primary" class="timeout-action-checkbox" v-model="bot.disambiguationAction.active" @change="openDisambiguationSettings">
              {{ 
                lang.botMaker.addBot.engineSetting.timeoutActionExecute[
                  languageSelected
                ]
              }}
            </vs-checkbox>
            <vs-button
              v-if="bot.disambiguationAction.active"
              class="no-intent-action-value"
              type="flat"
              icon="icon-settings"
              icon-pack="feather"
              @click="openDisambiguationSettings()"
            />
            <span v-if="bot.disambiguation" class="w-full no-intent-action-value"> </span>
          </div>
        </new-form-group>

        <!-- notDialogDetected -->
        <div class="flex">
          <div style="min-width: 190px" id="notDialogDetectedSwitch">
            <new-form-group 
              :label="lang.botMaker.addBot.engineSetting.notDialogDetected.text[languageSelected]"
              :tooltip="lang.botMaker.addBot.engineSetting.notDialogDetected.tooltip[languageSelected]">
              <vx-slider v-model="bot.notDialogDetected.active"/>
            </new-form-group>
          </div>

          <div class="ml-4 w-full" id="notDialogDetectedText">
            <new-form-group 
              v-show="bot.notDialogDetected.active"
              :label="lang.botMaker.addBot.engineSetting.notDialogDetectedWebhook[languageSelected]"
            >
              <vs-input
                class="w-full"
                :danger="validations.notDialogDetected"
                :danger-text="lang.botMaker.addBot.generalSettings.error.notDialogDetected.empty[languageSelected]"
                v-model="bot.notDialogDetected.url"
              />
            </new-form-group>
          </div>
        </div>


        <!-- entityExtractor -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.entityExtractor.text[languageSelected]
          "
          :tooltip="
            lang.botMaker.addBot.engineSetting.entityExtractor.tooltip[
              languageSelected
            ]
          "
        >
          <vx-slider v-model="bot.entityExtractor" />
        </new-form-group>
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.useAsTemplate.text[languageSelected]
          "
          :tooltip="
            lang.botMaker.addBot.engineSetting.useAsTemplate.tooltip[
              languageSelected
            ]
          "
        >
          <vx-slider v-model="bot.useAsTemplate" />
        </new-form-group>

        <!-- text when user is refered to an agent -->
        <IntentSecondLevelDerivation/>
      </template>
    </add-bot-section>

    <!-- engine settings -->
    <add-bot-section
      :title="lang.botMaker.addBot.engineSetting.title[languageSelected]"
      class="add-bot-section"
    >
      <template slot="whole-w">
        <!-- nlu platforms -->
        <new-form-group
          :label="lang.botMaker.addBot.engineSetting.platform[languageSelected]"
        >
          <!-- <ul>
            <li v-for="(platform, index) in nluPlatforms" :key="index">
              <vs-radio
                v-if="isEditing || (!isEditing && !platform.deprecated)"
                :disabled="isEditing || platform.deprecated"
                v-model="bot.nlu.platform"
                :vs-value="platform.value"
                data-cy="buttonPlataform"
              >
                {{ platform.text }}
                <span v-if="platform.deprecated">
                  ({{
                    lang.botMaker.addBot.engineSetting.deprecated[
                      languageSelected
                    ]
                  }})
                </span>
              </vs-radio>
            </li>
          </ul> -->
          <ButtonGroup
            class="mt-1"
            :options="nluPlatforms"
            v-model="bot.nlu.platform"
            :selected="bot.nlu.platform"
            :disabled="isEditing"
            :show-disabled="!isEditing"
            bg-color="primary"
            data-cy="buttonPlataform"
          />
          <div
            v-if="bot.nlu.platform === 'luis' || bot.nlu.platform === 'watson'"
            class="mt-4"
          >
            <EngineCredentialsTable :isEditing="isEditing" />
          </div>

          <kona-alert v-show="validations.nlu.platform">
            {{
              lang.botMaker.addBot.engineSetting.platformError[languageSelected]
            }}
          </kona-alert>
        </new-form-group>

        <!-- nlu culture -->
        <new-form-group
          :label="lang.botMaker.addBot.engineSetting.culture[languageSelected]"
        >
          <!-- <ul>
            <li v-for="(culture, index) in nluCulture" :key="index">
              <vs-radio
                :disabled="isEditing"
                v-model="bot.nlu.culture"
                :vs-value="culture.value"
                @change="languageChanged"
              >
                {{
                  lang.botMaker.addBot.engineSetting.cultureOptions[culture.text][
                    languageSelected
                  ]
                }}
              </vs-radio>
            </li>
          </ul> -->
          <ButtonGroup
            class="mt-1"
            :options="nluCulture"
            v-model="bot.nlu.culture"
            :selected="bot.nlu.culture"
            :disabled="isEditing"
            :show-disabled="!isEditing"
            bg-color="primary"
            @input="languageChanged"
          />
          <kona-alert v-show="validations.nlu.culture">
            {{
              lang.botMaker.addBot.engineSetting.cultureError[languageSelected]
            }}
          </kona-alert>
        </new-form-group>

        <!-- nlu intent confidence -->
        <new-form-group
          :label="
            lang.botMaker.addBot.engineSetting.intentConfidence[languageSelected]
          "
        >
          <!-- <ul>
            <li v-for="(confidence, index) in nluIntentConfidence" :key="index">
              <vs-radio
                v-model="bot.intentConfidence"
                :vs-value="confidence.value"
              >
                {{
                  lang.botMaker.addBot.engineSetting.intentConfidenceOptions[
                    confidence.text
                  ][languageSelected]
                }}
              </vs-radio>
            </li>
          </ul> -->
          <ButtonGroup
            class="mt-1"
            :options="nluIntentConfidence"
            v-model="bot.intentConfidence"
            :selected="bot.intentConfidence"
          />
          <kona-alert v-show="validations.intentConfidence">
            {{
              lang.botMaker.addBot.engineSetting.intentConfidenceError[
                languageSelected
              ]
            }}
          </kona-alert>
        </new-form-group>
        <!-- CORTE -->
      
      <KonaPopup
        ref="botTimeoutMessageValidationPopup"
        :title="
          lang.botMaker.addBot.engineSetting.timeoutAction[languageSelected]
        "
        :validate="validate"
        :button-close-hidden="true"
        :onAccept="saveValidation"
        :onCancel="cancelValidation"
        :onClosePopup="cancelValidation"
        :showActions="showMainPopupActions"
        class="bot-message-validation__form"
      >
        <div class="validation-setup" v-if="!jumpTimeoutPopupActive">
          <section class="form-group">
            <vs-input
              v-model="botTimeoutMessageValidation.service.name"
              :label="
                lang.botMaker.addBot.engineSetting.timeoutActionName[languageSelected]
              "
              name="name"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.first('name') }"
            />
            <span class="error-msg" v-if="errors.first('name')">
              {{
                lang.botMaker.botMessageValidation.form.name.error[languageSelected]
              }}
            </span>
          </section>

          <section class="form-group">
            <vs-input
              v-model="botTimeoutMessageValidation.question"
              class="w-full"
              :label="
                lang.botMaker.botMessageValidation.form.question.label[
                  languageSelected
                ]
              "
              name="question"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.first('question') }"
            />
            <span class="error-msg" v-if="errors.first('question')">
              {{
                lang.botMaker.botMessageValidation.form.question.error[
                  languageSelected
                ]
              }}
            </span>
          </section>
          <section class="form-group">
            <vs-input
              type="number"
              :min="0"
              v-model="botTimeoutMessageValidation.trigger.threshold"
              :label="
                lang.botMaker.botMessageValidation.form.waitTime2.label[
                  languageSelected
                ]
              "
              @keypress="isDigit($event)"
            />
          </section>  
          <section class="form-group">
            <vs-checkbox id="replyWhileWaitingForAgent" color="primary" class="timeout-action-checkbox" v-model="botTimeoutMessageValidation.replyWhileWaitingForAgent">
              {{ lang.botMaker.botMessageValidation.form.replyWhileWaitingForAgent.label[languageSelected]}}
            </vs-checkbox>
          </section>  
          <section class="form-group mt-4">
            <h2>
              {{
                lang.botMaker.botMessageValidation.form.answers.title[
                  languageSelected
                ]
              }}
            </h2>

            <table class="answers-table w-full">
              <!-- prettier-ignore -->
              <tr>
                <th>{{ lang.botMaker.botMessageValidation.form.answers.table.answer[languageSelected ] }}</th>
                <th>{{ lang.botMaker.botMessageValidation.form.answers.table.showOption[languageSelected] }}</th>
                <th>{{ lang.botMaker.botMessageValidation.form.answers.table.action[languageSelected] }}</th>
                <th></th>
              </tr>
              <!-- prettier-ignore -->
              <tr>
                <td class="w-1/5">
                  {{
                    lang.botMaker.botMessageValidation.form.answers.table.positive[
                      languageSelected
                    ]
                  }}
                </td>
                <td class="w-1/5">
                  <multiselect
                    v-model="botTimeoutMessageValidation.positive.text"
                    :allow-empty="false"
                    :options="positiveAnswers"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    
                  />
                </td>
                <td class="w-2/5">
                  <multiselect
                    :allow-empty="false"
                    v-model="botTimeoutMessageValidation.positive.actions[0]"
                    :options="getAvailableActions()"
                    label="name"
                    track-by="key"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :placeholder="lang.botMaker.botMessageValidation.form.answers.table.actionsPlaceholder[
                      languageSelected
                    ]"
                    @input="onTimeoutActionSelect('positive')"
                  />
                </td>
                <td class="w-1/5">
                  <vs-input
                    v-if="botTimeoutMessageValidation.positive.actions[0] && 
                      (botTimeoutMessageValidation.positive.actions[0].key === 'responseText' || 
                        botTimeoutMessageValidation.positive.actions[0].key === 'stopTakeoverAndText')"
                    v-model="botTimeoutMessageValidation.positive.actions[0].args[0]"
                    class="w-full"
                  />
              <!-- v-validate="'required'"
              :class="{ 'is-invalid': errors.first('question') }" -->
            <!-- <span class="error-msg" v-if="errors.first('question')">
              {{
                lang.botMaker.botMessageValidation.form.question.error[
                  languageSelected
                ]
              }}
            </span> -->
                  <span
                    v-if="
                      botTimeoutMessageValidation.positive.actions[0] &&
                      (botTimeoutMessageValidation.positive.actions[0].key === 'jump'
                      || botTimeoutMessageValidation.positive.actions[0].key === 'stopTakeoverAndJump') &&
                        botTimeoutMessageValidation.positive.actions[0].args[0] !== ''
                    "
                  >
                    {{
                        dialogsMap[botTimeoutMessageValidation.positive.actions[0].args[0]] && dialogsMap[botTimeoutMessageValidation.positive.actions[0].args[0]]
                        .name
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="w-1/5">
                  {{
                    lang.botMaker.botMessageValidation.form.answers.table.negative[
                      languageSelected
                    ]
                  }}
                </td>
                <td class="w-1/5">
                  <multiselect
                    v-model="botTimeoutMessageValidation.negative.text"
                    :allow-empty="false"
                    :options="negativeAnswers"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                  />
                </td>
                <td class="w-2/5">
                  <multiselect
                    :allow-empty="false"
                    v-model="botTimeoutMessageValidation.negative.actions[0]"
                    :options="getAvailableActions()"
                    label="name"
                    track-by="key"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :placeholder="
                      lang.botMaker.botMessageValidation.form.answers.table
                        .actionsPlaceholder[languageSelected]
                    "
                    @input="onTimeoutActionSelect('negative')"
                  />
                </td>
                <td class="w-1/5">
                  <vs-input
                    v-if="
                      botTimeoutMessageValidation.negative.actions[0] &&
                        (botTimeoutMessageValidation.negative.actions[0].key ===
                          'responseText'
                        || botTimeoutMessageValidation.negative.actions[0].key === 'stopTakeoverAndText')

                    "
                    v-model="botTimeoutMessageValidation.negative.actions[0].args[0]"
                    class="w-full"
                  />

                  <span
                    v-if="
                      botTimeoutMessageValidation.negative.actions[0] &&
                        (botTimeoutMessageValidation.negative.actions[0].key === 'jump' || botTimeoutMessageValidation.negative.actions[0].key === 'stopTakeoverAndJump') &&
                        botTimeoutMessageValidation.negative.actions[0].args[0] !== ''
                    "
                  >
                    {{
                      dialogsMap[
                        botTimeoutMessageValidation.negative.actions[0].args[0]
                      ] &&
                        dialogsMap[botTimeoutMessageValidation.negative.actions[0].args[0]]
                          .name
                    }}
                  </span>
                </td>
              </tr>
            </table>
          </section>

          <section class="form-group mt-4">
            <h6>
              {{
                lang.botMaker.botMessageValidation.form.repeatTimes2.title[
                  languageSelected
                ]
              }}
            </h6>

            <div class="ask-again">
              <vs-input
                type="number"
                v-model.number="botTimeoutMessageValidation.askAgain"
                min="0"
              />
              <span class="ml-2">
                {{
                  lang.botMaker.botMessageValidation.form.repeatTimes.times[
                    languageSelected
                  ]
                }}
              </span>
            </div>
          </section>

          <!-- TODO: revert 1521 once its working fine -->
          <!-- <section class="form-group mt-4">
            <span class="flex">
              <vx-slider color="success" v-model="applyAll" class="mr-2" />
              {{
                lang.botMaker.botMessageValidation.form.applyAll[languageSelected]
              }}
            </span>
          </section> -->
          <!-- TODO: revert 1521 once its working fine -->
        </div>

        <div class="validation-jump" v-else>
          <KonaTree
            :key="keyRefresh"
            :tree-data="treeData"
            :searchBar="true"
            :showJump="false"
            :halfcheck="false"
            :multiple="false"
            :radio="true"
            :canMove="false"
            :serachWidht="'w-1/2'"
            :marginTop="'mt-2'"
            @clickNode="clickNode"
            ref="konaTimeoutTree"
          />

          <div class="jumpFooter">
            <vs-divider />
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="closeJumpPopup" type="flat" color="danger">
                  {{ lang.botMaker.integrations.cancel[languageSelected] }}
                </vs-button>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="deleteJump" color="danger">
                  <!-- :disabled="!jumpDialogId" -->
                  {{ lang.botMaker.jump.remove.title[languageSelected] }}
                </vs-button>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="updateJump" :disabled="!jumpDialog">
                  {{ lang.botMaker.jump.update.title[languageSelected] }}
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </KonaPopup>

      <KonaPopup
        ref="botDisambiguationPopup"
        :title="
          lang.botMaker.addBot.engineSetting.disambiguation.text[
            languageSelected
          ]
        "
        :validate="validateDisambiguation"
        :button-close-hidden="true"
        :onAccept="saveDisambiguation"
        :onCancel="cancelDisambiguation"
        :onClosePopup="cancelDisambiguation"
        :showActions="showMainPopupActions"
        class="bot-message-validation__form"
      >
        <div class="validation-setup" v-if="!jumpTimeoutPopupActive">
        <section class="form-group form-group-0">
          <vs-input
            class="fill"
            v-model="bot.disambiguationAction.message"
            :label="
              lang.botMaker.addBot.engineSetting.disambiguation.message[languageSelected]
            "
            :danger="disambiguationValidator.message"
            :danger-text="
              lang.botMaker.addBot.engineSetting.noIntentMessageError[
                languageSelected
              ]
            "
          />
        </section>
        <section class="form-group form-group-0">
          <vs-input
              type="number"
              :min="1"
              :max="5"
              v-model="bot.disambiguationAction.maxIntents"
              :label="
                lang.botMaker.addBot.engineSetting.disambiguation.maxIntents[languageSelected]
              "
              :danger="disambiguationValidator.maxIntents"
              :danger-text="
                lang.botMaker.addBot.engineSetting.disambiguation.maxIntentsError[
                  languageSelected
                ]
              "
            />
        </section>

        <section class="form-group">
          <vs-checkbox color="primary" class="timeout-action-checkbox" v-model="bot.disambiguationAction.anythingElseActions.active">
            {{ lang.botMaker.addBot.engineSetting.disambiguation.anythingElseMessage.title[
              languageSelected
            ] }}
          </vs-checkbox>
        </section>
        <section class="form-group mt-4">
          <vs-input
            v-if="bot.disambiguationAction.anythingElseActions.active"
            class="fill"
            v-model="bot.disambiguationAction.anythingElseActions.text"
            :label="
              lang.botMaker.addBot.engineSetting.disambiguation.anythingElseMessage.message[languageSelected]
            "
            :danger="disambiguationValidator.anythingElse.message"
            :danger-text="
              lang.botMaker.addBot.engineSetting.noIntentMessageError[
                languageSelected
              ]
            "
          />
          <table class="answers-table w-full" v-if="bot.disambiguationAction.anythingElseActions.active">
            <!-- prettier-ignore -->
            <tr>
              <th>{{ lang.botMaker.botMessageValidation.form.answers.table.action[languageSelected] }}</th>
              <th></th>
            </tr>
            <!-- prettier-ignore -->
            <tr>

              <td class="w-1/5">
                <multiselect
                  :allow-empty="false"
                  v-model="bot.disambiguationAction.anythingElseActions.actions[0]"
                  :options="getAvailableDisambiguationActions()"
                  label="name"
                  track-by="key"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :placeholder="lang.botMaker.botMessageValidation.form.answers.table.actionsPlaceholder[
                    languageSelected
                  ]"
                  @input="onDisambiguationJumpActionSelect()"
                />
              </td>
              <td class="w-3/5">
                <vs-input
                  v-if="bot.disambiguationAction.anythingElseActions.actions[0] && (bot.disambiguationAction.anythingElseActions.actions[0].key === 'responseText')"
                  v-model="bot.disambiguationAction.anythingElseActions.actions[0].args[0]"
                  class="w-full"
                  :danger="disambiguationValidator.anythingElse.actionMessage"
                  :danger-text="
                    lang.botMaker.addBot.engineSetting.noIntentMessageError[
                      languageSelected
                    ]
                  "
                />
                <div class="disambiguationActionJumpOption">
                  <vs-button
                    v-if="
                      bot.disambiguationAction.anythingElseActions.actions[0] &&
                      bot.disambiguationAction.anythingElseActions.actions[0].key === 'jump' &&
                        bot.disambiguationAction.anythingElseActions.actions[0].args[0] !== ''
                    "
                    size="small"
                    class="integration-settings-btn no-intent-action-value"
                    icon="icon-settings"
                    icon-pack="feather"
                    @click="onDisambiguationJumpActionSelect()"
                  ></vs-button>
                  <span
                    v-if="
                      bot.disambiguationAction.anythingElseActions.actions[0] &&
                      bot.disambiguationAction.anythingElseActions.actions[0].key === 'jump' &&
                        bot.disambiguationAction.anythingElseActions.actions[0].args[0] !== ''
                    "
                  >
                    {{
                        dialogsMap[bot.disambiguationAction.anythingElseActions.actions[0].args[0]] && dialogsMap[bot.disambiguationAction.anythingElseActions.actions[0].args[0]]
                        .name
                    }}
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </section>
        </div>

        <div class="validation-jump" v-else>
          <KonaTree
            v-if="jumpTimeoutPopupActive"
            :tpl="'basic'"
            :key="keyRefresh"
            :tree-data="treeData"
            :searchBar="true"
            :showJump="false"
            :halfcheck="false"
            :multiple="false"
            :radio="true"
            :canMove="false"
            :serachWidht="'w-1/2'"
            :marginTop="'mt-2'"
            @clickNode="clickNode"
            ref="konaTimeoutTree"
          />

          <div class="jumpFooter">
            <vs-divider />
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="closeJumpPopup" type="flat" color="danger">
                  {{ lang.botMaker.integrations.cancel[languageSelected] }}
                </vs-button>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="deleteJump" color="danger">
                  <!-- :disabled="!jumpDialogId" -->
                  {{ lang.botMaker.jump.remove.title[languageSelected] }}
                </vs-button>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button @click="updateJump" :disabled="!jumpDialog">
                  {{ lang.botMaker.jump.update.title[languageSelected] }}
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </KonaPopup>
      </template>
    </add-bot-section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AddBotSection from './Components/AddBotSection'
import NewFormGroup from './Components/NewFormGroup'
import EngineCredentialsTable from './EngineCredentials/EngineCredentialsTable'
import botTreeMixin from '@/mixins/botTreeMixin'
import IntentSecondLevelDerivation from './Intent/IntentSecondLevelDerivation'

export default {
  name: 'GeneralEngineSetting',
  props: {
    isEditing: Boolean,
    noIntentActionSelected: {
      type: Function,
      required: true
    }
  },
  components: {
    AddBotSection,
    NewFormGroup,
    EngineCredentialsTable,
    IntentSecondLevelDerivation,
    KonaPopup: () => import('@/components/KonaPopup.vue'),
    KonaTree: () => import('@/components/KonaTree.vue'),
    Multiselect: () => import('vue-multiselect'),
    ButtonGroup: () => import('@/components/ButtonGroup.vue')
  },
  data() {
    return {
      maxWaitAgentTime: 0,
      hasTimeout: false,
      hasDisambiguationAction: false,
      isMounted: false,
      jumpTimeoutPopupActive: false,
      jumpDialog: null,
      disambiguationValidator: {
        message: false,
        maxIntents: false,
        anythingElse: {
          message: false,
          actionMessage: false,
          jumpDialog: false
        }
      },
      jumpPopupType: null,
      keyRefresh: new Date().getTime(),
      previousAction: null,
      previousHasTimeoutValidation: false,
      previousDisambiguationAction: null,
      nluPlatforms: [],
      nluCulture: [],
      nluIntentConfidence: [],
      showMainPopupActions: true
    }
  },
  mixins: [botTreeMixin],
  watch: {
    hasTimeout(newVal) {
      if (!newVal) {
        this.previousHasTimeoutValidation = false
      }
      this.SET_TIMEOUT_VALIDATION(newVal)
    },
    'bot.noIntentAction'(newVal) {
      this.bot.noIntentAction = newVal
    },
    'bot.nlu.platform'(newVal) {
      if (newVal === 'rasa' || newVal === 'rasa_v2' || newVal === 'watson') {
        this.bot.intentConfidence = this.nluIntentConfidence[1].value
      } else if (newVal === 'luis') {
        this.bot.intentConfidence = this.nluIntentConfidence[2].value
      }
    },
    'bot.nlu.culture'(newVal) {
      if (!this.isEditing) {
        const lang = newVal.split('-')[0]
        this.bot.noIntentMessage = this.lang.botMaker.addBot.generalSettings.form.noIntentMessage[
          lang
        ]
        this.bot.connectionErrorMessage = this.lang.botMaker.addBot.generalSettings.form.connectionErrorMessage[
          lang
        ]
      }
    },
    languageSelected() {
      this.initOptions()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', [
      'bot',
      'validations',
      'sectionReady',
      'botTimeoutMessageValidation',
      'botMessageInEdit',
      'botValidationStatus',
      'hasTimeoutValidation'
    ]),
    ...mapGetters('credentials', ['nlu']),
    ...mapGetters('botMaker', ['dialogsMap', 'botValidationEnabled']),
    botLanguage() {
      return this.bot.nlu.culture.split('-')[0]
    },
    positiveAnswers() {
      return [
        this.lang.botMaker.botMessageValidation.form.answers.table
          .positiveOptions.option1[this.botLanguage],
        this.lang.botMaker.botMessageValidation.form.answers.table
          .positiveOptions.option3[this.botLanguage]
      ]
    },
    negativeAnswers() {
      return [
        this.lang.botMaker.botMessageValidation.form.answers.table
          .negativeOptions.option1[this.botLanguage],
        this.lang.botMaker.botMessageValidation.form.answers.table
          .negativeOptions.option3[this.botLanguage]
      ]
    },
    botValidationStatus: {
      get() {
        return this.botValidationEnabled
      },
      set(val) {
        this.SET_VALIDATION_STATUS(val)
      }
    }
  },
  methods: {
    ...mapMutations(['UPDATE_NEW_BOT_LANGUAGE']),
    ...mapMutations('bots', [
      'UPDATE_BOT_NO_INTENT_MESSAGE',
      'UPDATE_BOT_CONNECTION_ERROR_MESSAGE',
      'UPDATE_DISAMBIGUATION_MESSAGES',
      'SET_TIMEOUT_VALIDATION',
      'SET_OPTION',
      'SET_DISAMBIGUATION'
    ]),
    ...mapMutations('botMaker', ['SET_VALIDATION_STATUS']),
    async validate() {
      const valid = await this.$validator.validate()
      if (valid) {
        if (this.botMessageInEdit.isSaved) {
          const validationSettings = this.dialogsMap[
            this.botMessageInEdit.dialogId
          ].sendValidationSettings

          let currentValidation = null
          if (validationSettings) {
            currentValidation = this.responseValidations.find(
              v => v._id === validationSettings.validation
            )
          }
          if (!currentValidation) return valid
          const sameValidations = this.lodash.isEqual(
            this.normalizeVal(currentValidation),
            this.normalizeVal(this.botMessageValidation)
          )
          if (!sameValidations) {
            this.SET_VALIDATION_UPDATE_MODE('update')
            this.$refs.botUpdateValidationPopup.openPopup()
            return false
          } else {
            return valid
          }
        } else {
          return valid
        }
      } else {
        // invalid form
        return valid
      }
    },
    async validateDisambiguation() {
      if (!this.bot.disambiguationAction.message) {
        this.disambiguationValidator.message = true
      } else {
        this.disambiguationValidator.message = false
      }
      if (
        this.bot.disambiguationAction.anythingElseActions.active &&
        !this.bot.disambiguationAction.anythingElseActions.text
      ) {
        this.disambiguationValidator.anythingElse.message = true
      } else {
        this.disambiguationValidator.anythingElse.message = false
      }
      if (
        !this.bot.disambiguationAction.maxIntents ||
        parseInt(this.bot.disambiguationAction.maxIntents) > 5 ||
        parseInt(this.bot.disambiguationAction.maxIntents) < 1
      ) {
        this.disambiguationValidator.maxIntents = true
      } else {
        this.disambiguationValidator.maxIntents = false
      }
      if (
        this.bot.disambiguationAction.anythingElseActions.active &&
        !this.bot.disambiguationAction.anythingElseActions.actions[0].args[0] &&
        this.bot.disambiguationAction.anythingElseActions.actions[0].key ===
          'responseText'
      ) {
        this.disambiguationValidator.anythingElse.actionMessage = true
      } else {
        this.disambiguationValidator.anythingElse.actionMessage = false
      }
      if (
        this.bot.disambiguationAction.anythingElseActions.active &&
        !this.bot.disambiguationAction.anythingElseActions.actions[0].args[0] &&
        this.bot.disambiguationAction.anythingElseActions.actions[0].key ===
          'jump'
      ) {
        this.disambiguationValidator.anythingElse.jumpDialog = true
        this.$vs.notify({
          text: this.lang.botMaker.jumpError[this.languageSelected],
          color: 'warning'
        })
      } else {
        this.disambiguationValidator.anythingElse.jumpDialog = false
      }
      return (
        !this.disambiguationValidator.message &&
        !this.disambiguationValidator.maxIntents &&
        !this.disambiguationValidator.anythingElse.message &&
        !this.disambiguationValidator.anythingElse.actionMessage &&
        !this.disambiguationValidator.anythingElse.jumpDialog
      )
    },
    validateDisambiguationReset() {
      this.disambiguationValidator.message = false
      this.disambiguationValidator.anythingElse.message = false
      this.disambiguationValidator.maxIntents = false
      this.disambiguationValidator.anythingElse.actionMessage = false
    },
    languageChanged() {
      const newBotLanguage = this.bot.nlu.culture.split('-')[0]
      const BOT_ID = this.$route.query.botId || this.$route.query.service
      if (!BOT_ID) {
        this.UPDATE_NEW_BOT_LANGUAGE(newBotLanguage)
        this.UPDATE_BOT_NO_INTENT_MESSAGE(newBotLanguage)
        this.UPDATE_BOT_CONNECTION_ERROR_MESSAGE()
        this.UPDATE_DISAMBIGUATION_MESSAGES()
      }
    },
    isDigit(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    getAvailableDisambiguationActions() {
      return [
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
            this.languageSelected
          ],
          key: 'jump',
          args: []
        }
      ]
    },
    getAvailableActions() {
      return [
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table
            .stopTakeover[this.languageSelected],
          key: 'stopTakeover',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
            this.languageSelected
          ],
          key: 'jump',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table
            .stopTakeoverAndText[this.languageSelected],
          key: 'stopTakeoverAndText',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table
            .stopTakeoverAndJump[this.languageSelected],
          key: 'stopTakeoverAndJump',
          args: []
        }
      ]
    },
    getAvailableNoIntentActions() {
      return [
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
            this.languageSelected
          ],
          key: 'jump',
          args: []
        }
      ]
    },
    saveDisambiguation() {
      this.hasDisambiguationAction = true
    },
    saveValidation() {
      if (
        this.botTimeoutMessageValidation.positive.actions[0] &&
        this.botTimeoutMessageValidation.positive.actions[0].key === ''
      ) {
        this.botTimeoutMessageValidation.positive.actions = []
      }
      if (
        this.botTimeoutMessageValidation.negative.actions[0] &&
        this.botTimeoutMessageValidation.negative.actions[0].key === ''
      ) {
        this.botTimeoutMessageValidation.negative.actions = []
      }
      this.previousHasTimeoutValidation = true
      this.SET_TIMEOUT_VALIDATION(true)
    },
    cancelValidation() {
      this.SET_OPTION(this.previousAction)
      this.hasTimeout = this.previousHasTimeoutValidation
      if (!this.botMessageInEdit.isSaved) {
        this.botValidationStatus = false
      }
      this.$validator.reset()
    },
    cancelDisambiguation() {
      this.previousDisambiguationAction.active = this.hasDisambiguationAction
      this.SET_DISAMBIGUATION(this.previousDisambiguationAction)
      this.validateDisambiguationReset()
    },
    async onTimeoutActionSelect(responseType) {
      this.responseType = responseType
      const actionType = this.botTimeoutMessageValidation[this.responseType]
        .actions[0]
        ? this.botTimeoutMessageValidation[this.responseType].actions[0].key
        : ''

      if (actionType === 'jump' || actionType === 'stopTakeoverAndJump') {
        await this.initTree()
        this.jumpTimeoutPopupActive = true
        this.showMainPopupActions = false
        this.jumpPopupType = 'timeout'
      }
    },
    clickNode(node) {
      this.jumpDialog = node[0] || null
    },
    onCloseJumps() {
      this.$refs.konaTimeoutTree.reset()
    },
    closeJumpPopup() {
      //when tree popup closed without saving, restore last selection
      if (this.jumpPopupType === 'timeout') {
        this.botTimeoutMessageValidation[
          this.responseType
        ].actions[0] = this.previousAction
      }
      this.jumpTimeoutPopupActive = false
      this.showMainPopupActions = true
    },
    updateJump() {
      if (this.jumpPopupType === 'timeout') {
        this.botTimeoutMessageValidation[this.responseType].actions[0].args = [
          this.jumpDialog.id
        ]
        this.jumpDialog = null
        this.jumpTimeoutPopupActive = false
        this.showMainPopupActions = true
      } else if (this.jumpPopupType === 'disambiguation') {
        this.bot.disambiguationAction.anythingElseActions.actions[0].args = [
          this.jumpDialog.id
        ]
        this.jumpDialog = null
        this.jumpTimeoutPopupActive = false
        this.showMainPopupActions = true
      }
    },
    deleteJump() {
      this.bot.disambiguationAction.anythingElseActions.actions[0].args[0] = null
      this.closeJumpPopup()
    },
    initValidation() {
      this.botTimeoutMessageValidation.positive.text = this.positiveAnswers[0]
      this.botTimeoutMessageValidation.negative.text = this.negativeAnswers[0]
    },
    openTimeoutSettings() {
      if (!this.hasTimeout) {
        this.previousHasTimeoutValidation = false
      }
      if (this.$refs.botTimeoutMessageValidationPopup && this.hasTimeout) {
        this.previousAction = JSON.parse(
          JSON.stringify(this.botTimeoutMessageValidation)
        )
        this.$refs.botTimeoutMessageValidationPopup.openPopup()
      }
    },
    openDisambiguationSettings() {
      if (!this.bot.disambiguationAction.active) {
        this.hasDisambiguationAction = false
      }
      if (
        this.$refs.botDisambiguationPopup &&
        this.bot.disambiguationAction.active
      ) {
        this.previousDisambiguationAction = JSON.parse(
          JSON.stringify(this.bot.disambiguationAction)
        )
        this.$refs.botDisambiguationPopup.openPopup()
      }
    },
    onActionSelect() {
      const actionType = this.bot.noIntentAction
        ? this.bot.noIntentAction.key
        : ''
      if (actionType === 'responseText' && !this.bot.noIntentAction.args[0]) {
        this.bot.noIntentAction.args = [
          this.lang.botMaker.addBot.generalSettings.form.noIntentMessage[
            this.languageSelected
          ]
        ]
      }
      if (actionType === 'jump') {
        this.noIntentActionSelected()
      }
    },
    async onDisambiguationJumpActionSelect() {
      const actionType =
        this.bot.disambiguationAction &&
        this.bot.disambiguationAction.anythingElseActions &&
        this.bot.disambiguationAction.anythingElseActions.actions &&
        this.bot.disambiguationAction.anythingElseActions.actions[0]
          ? this.bot.disambiguationAction.anythingElseActions.actions[0].key
          : ''

      if (actionType === 'jump') {
        await this.initTree()
        this.jumpTimeoutPopupActive = true
        this.showMainPopupActions = false
        this.jumpPopupType = 'disambiguation'
      }
    },
    openSettings() {
      this.noIntentActionSelected()
    },
    initOptions() {
      this.nluPlatforms = [
        {
          text: 'RASA',
          value: 'rasa',
          deprecated: true
        },
        {
          text: 'KONA AI',
          value: 'rasa_v2',
          deprecated: false,
          icon: 'kona.png'
        },
        {
          text: 'Microsoft LUIS',
          value: 'luis',
          deprecated: false,
          icon: 'luis.png'
        },
        {
          text: 'IBM Watson',
          value: 'watson',
          deprecated: false,
          icon: 'watson.png'
        }
      ]

      this.nluCulture = [
        {
          text: this.lang.botMaker.addBot.engineSetting.cultureOptions.spanish[
            this.languageSelected
          ],
          value: 'es-ES'
        },
        {
          text: this.lang.botMaker.addBot.engineSetting.cultureOptions.english[
            this.languageSelected
          ],
          value: 'en-EN'
        },
        {
          text: this.lang.botMaker.addBot.engineSetting.cultureOptions
            .portuguese[this.languageSelected],
          value: 'pt-BR'
        }
      ]

      this.nluIntentConfidence = [
        {
          text: this.lang.botMaker.addBot.engineSetting.intentConfidenceOptions
            .high[this.languageSelected],
          value: 0.85
        },
        {
          text: this.lang.botMaker.addBot.engineSetting.intentConfidenceOptions
            .medium[this.languageSelected],
          value: 0.6
        },
        {
          text: this.lang.botMaker.addBot.engineSetting.intentConfidenceOptions
            .low[this.languageSelected],
          value: 0.25
        }
      ]
    }
  },
  mounted() {
    this.initOptions()
    this.initValidation()
    this.isMounted = false
    this.hasTimeout = this.hasTimeoutValidation
    this.hasDisambiguationAction = this.bot.disambiguationAction.active
    this.previousHasTimeoutValidation = this.hasTimeoutValidation
    this.languageChanged()
    if (!this.botTimeoutMessageValidation.negative.actions[0].name) {
      this.botTimeoutMessageValidation.negative.actions[0].name = this.lang.botMaker.botMessageValidation.form.answers.table.stopTakeover[
        this.languageSelected
      ]
    }
    if (!this.botTimeoutMessageValidation.positive.actions[0].name) {
      this.botTimeoutMessageValidation.positive.actions[0].name = this.lang.botMaker.botMessageValidation.form.answers.table.stopTakeover[
        this.languageSelected
      ]
    }
    this.isMounted = true
    this.sectionReady.engineSettings = true
  }
}
</script>

<style lang="scss">
.no-intent-action-key {
  width: 200px !important;
  min-width: 0% !important;
}
.ask-again {
  margin-top: 5px;
  display: flex;
  align-items: center;

  .vs-con-input-label {
    max-width: 50px;
  }
}
.no-intent-action {
  display: flex;
  align-items: center;
  height: 100%;
  .multiselect {
    width: 200px !important;
  }
  .no-intent-action-value {
    margin-left: 10px;
  }
  .timeout-action-checkbox {
    z-index: 0;
  }
  .timeout-action-checkbox-negative {
    z-index: 0;
    max-width: 110px;
    .checkbox_x {
      min-width: 17px;
      min-height: 17px;
      max-width: 17px;
      max-height: 17px;
    }
  }
  .integration-settings-btn {
    width: 33px !important;
    height: 33px !important;
    display: inline-block;
    z-index: 0;
    background: #fafafa !important;
    border-radius: 2px;
    color: rgba(var(--vs-primary), 1);
    border: none;
    padding: 0px;
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}
.disambiguationActionJumpOption {
  display: flex;
  align-items: center;
  .vs-button {
    width: 33px !important;
    height: 33px !important;
    display: inline-block;
    z-index: 0;
    background: #fafafa !important;
    border-radius: 2px;
    color: rgba(var(--vs-primary), 1);
    border: none;
    padding: 0px;
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}
.form-group-0 {
  margin-bottom: 20px !important;
}
.add-bot-section {
  &__check {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-right: 10px;
    word-break: break-all;
    .current-validation {
      margin-left: 30px;
    }
    .help {
      right: -25px !important;
      top: -4px !important;
    }
  }
  &__form {
    .form-group {
      align-items: center;
      margin-bottom: 20px;
      h2 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .answers-table {
        tr {
          th {
            padding-left: 0;
            padding-right: 20px;
          }
          td:not(:first-of-type):not(:last-of-type) {
            padding-right: 10px;
          }
        }
      }
      .ask-again {
        display: flex;
        align-items: center;

        .vs-con-input-label {
          max-width: 50px;
        }
      }
    }
  }
}
</style>