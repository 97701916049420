<template>
  <section class="form-group">
    <div class="form-group__label">
      <h3 v-if="header">{{ header }}</h3>
      <label v-else-if="label">{{ label }}</label>
      <vx-tooltip v-if="tooltip" :text="tooltip" position="top">
        <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="ml-2" />
      </vx-tooltip>
    </div>
    <div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    header: String,
    label: String,
    tooltip: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.form-group {
  margin-bottom: 24px;
  margin-top: 24px;

  &__label {
    display: flex;
    font-size: 14px;

    .con-vs-tooltip {
      display: inline-block;
    }
    h3{
      font-size: 16px;
    }
  }

  .multiselect__tags {
    min-height: 37.5px;
  }
}
</style>
