<template>
  <add-bot-section :title="lang.forking.forkingTableTitle[languageSelected]">
    <template slot="whole-w">
      <vs-checkbox class="mb-4" v-model="activeForking">
        {{ lang.forking.forkingTableTitle[languageSelected] }}
      </vs-checkbox>
      <div v-if="activeForking" class="flex ml-8">
        <div>
          <p>{{ lang.forking.selectBot[languageSelected] }}</p>
          <vs-table
            pagination
            v-model="selected"
            :data="getBots"
            :no-data-text="lang.general.noDataText[languageSelected]"
            @selected="handleSelectedBot"
          >
            <template slot="thead">
              <vs-th sort-key="name">Bot</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr]._botService.name">{{
                  data[indextr]._botService.name
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

        <div>
          <p>{{ lang.forking.selectVersion[languageSelected] }}</p>
          <vs-table
            v-if="selected"
            class="ml-4"
            v-model="versionSelected"
            pagination
            sorted
            @selected="handleSelectedVersion"
            :data="versions"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>
                {{ lang.surveyMaker.versions.table.tag[languageSelected] }}
              </vs-th>
              <vs-th sort-key="status">
                {{ lang.surveyMaker.versions.table.status[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{
                  lang.surveyMaker.versions.table._createdAt[languageSelected]
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].tag">
                  {{ data[indextr].tag }}
                </vs-td>

                <vs-td :data="data[indextr].status">
                  <vs-chip
                    :color="
                      data[indextr].status === 'production' ? 'success' : ''
                    "
                  >
                    {{
                      lang.surveyMaker[data[indextr].status][languageSelected]
                    }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="data[indextr]._createdAt">
                  {{ new Date(data[indextr]._createdAt) | date(true) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </template>
  </add-bot-section>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import AddBotSection from '../Components/AddBotSection'
import services from '../../../survey-maker/services'

export default {
  name: 'ForkingTable',
  props: {
    forking: Boolean
  },
  components: {
    AddBotSection
  },
  data() {
    return {
      selected: null,
      activeForking: false,
      versionSelected: null,
      versions: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('credentials', ['nlu']),
    ...mapGetters('bots', ['bot', 'getBots']),
    auxNLU() {
      if (!this.nlu) {
        return []
      }
      return this.nlu.filter(element => element.active)
    }
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    ...mapMutations('bots', ['SET_FORK']),
    handleSelected(tr) {
      if (tr && tr._id) {
        this.bot.credential = tr._id
      }
    },
    async handleSelectedBot(tr) {
      this.$vs.loading()
      try {
        await this.getVersions(tr._botService.id)
        this.versionSelected =
          this.versions.length > 0 ? this.versions[0] : null
        this.handleSelectedVersion(this.versionSelected)
        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
      }
    },
    async getVersions(service) {
      try {
        const result = await services.getVersions(service)
        this.versions = result.data.map(element => ({
          _id: element._id,
          active: element.active,
          tag: element.tag || 'N/A',
          cantSurvey: element.cantSurvey,
          status: element.active ? 'production' : 'development',
          _createdAt: element._createdAt
        }))
      } catch (error) {
        console.error('error', error)
      }
    },
    handleSelectedVersion(tr) {
      this.SET_FORK({
        from: {
          service: this.selected._botService.id,
          version: tr._id
        },
        intents: true,
        entities: true
      })
    }
  },
  watch: {
    activeForking(newVal) {
      if (newVal) {
        this.GET_BOTS()
        this.SET_FORK({
          from: {},
          intents: true,
          entities: true
        })
      } else {
        this.selected = null
        this.SET_FORK(undefined)
      }
    }
  },
  mounted() {
    if (this.auxNLU && this.auxNLU.length > 0) {
      this.selected = this.auxNLU[0]
      this.bot.credential = this.auxNLU[0]._id
    }
  }
}
</script>

<style type="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
</style>
