<template>
  <add-bot-section
    id="user-administration"
    :title="lang.botMaker.addBot.manageUsers.title[languageSelected]"
  >
    <template slot="actions">
      <select-users
        v-if="isSelectUsersOpen"
        :toggle-prompt="toggleIsSelectUsersOpen"
        :onlyAgents="true"
      />
      <vs-button
        color="primary"
        type="filled"
        icon="book"
        @click="selectUsers"
        v-if="!readOnly && (canCreateUser || isAuthAux('konecta.bot_maker.edit_bot_settings', false))"
      >
        {{ lang.botMaker.addBot.manageUsers.button[languageSelected] }}
      </vs-button>
    </template>
    <template slot="whole-w" v-if="hasUsers">
      <users-table :users="users" :show-channels="true" />
    </template>
    <template slot="whole-w" v-else>
      {{ lang.users.manageUsers.empty.title[languageSelected]}}
    </template>
  </add-bot-section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import SelectUsers from './Users/SelectUsers'
import UsersTable from './Users/UsersTable'
// import Roles from '../../../../models/Roles'
import AddBotSection from './Components/AddBotSection'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'usersAdministration',
  components: {
    AddBotSection,
    SelectUsers,
    UsersTable
  },
  mixins: [staffManagementMixin],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectUsersOpen: false,
      usersTableProps: {
        isMultiple: false,
        isSearchable: true,
        isPaginated: true
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', [
      'bot',
      'validations',
      'isAddUserPromptOpened',
      // 'users',
      'sectionReady'
    ]),
    hasUsers() {
      if (this.bot && this.bot.service && this.bot.service.users) {
        return this.bot.service.users.length > 0
      }

      return false
    },
    users() {
      if (this.bot && this.bot.service && this.bot.service.users) {
        return this.bot.service.users
      }

      return []
    },
    canCreateUser() {
      // return true
      return this.session.user.roles.canAccess.users
      // const userRole = this._.find(Roles, r => {
      //   return r.defaultName === this.session.roles[0]
      // })

      // return userRole.canCreate.length !== 0
    }
  },
  methods: {
    ...mapMutations('bots', [
      'UPDATE_ADD_USER_PROMPT',
      'SET_USER_MODEL',
      'UPDATE_SELECT_USER_PROMPT',
      'SET_BOT_USERS'
    ]),
    ...mapActions('bots', ['REMOVE_USER', 'LOAD_USERS', 'LOAD_AGENTS']),
    selectUsers: function() {
      this.isSelectUsersOpen = !this.isSelectUsersOpen
      this.UPDATE_SELECT_USER_PROMPT(true)
    },
    toggleIsSelectUsersOpen: function() {
      this.isSelectUsersOpen = !this.isSelectUsersOpen
    }
  },
  mounted() {
    (this.useStaffManagement ? this.LOAD_USERS : this.LOAD_AGENTS)()
      .then(() => {
        this.SET_BOT_USERS()
      })
      .finally(() => {
        this.sectionReady.usersAdministration = true
      })
  }
}
</script>
