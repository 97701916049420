<template>
  <add-bot-section
    :title="lang.botMaker.addBot.botAvailability.title[languageSelected]"
  >
    <template slot="whole-w">
      <days-available />
    </template>
    <template slot="whole-w">
      <days-exceptions />
    </template>
  </add-bot-section>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DaysAvailable from './Availability/DaysAvailable'
import DaysExceptions from './Availability/DaysExceptions'
import AddBotSection from './Components/AddBotSection'

export default {
  name: 'BotAvailability',
  components: {
    AddBotSection,
    DaysAvailable,
    DaysExceptions
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'sectionReady'])
  },
  mounted() {
    this.sectionReady.botAvailability = true
  }
}
</script>
