var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"select-users-prompt"}},[_c('vs-prompt',{attrs:{"is-valid":_vm.isValid,"vs-active":_vm.openSelectUsers,"vs-accept-text":_vm.lang.botMaker.addBot.manageUsers.dialogSelectUsers.selectUsers[
        _vm.languageSelected
      ],"vs-title":_vm.lang.botMaker.addBot.manageUsers.dialogSelectUsers.title[
        _vm.languageSelected
      ],"vs-cancel-text":_vm.lang.botMaker.cancel[_vm.languageSelected]},on:{"update:vsActive":function($event){_vm.openSelectUsers=$event},"vs-accept":_vm.onSelectUsers,"vs-close":_vm.onClose,"vs-cancel":_vm.onClose}},[_c('kona-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isValid),expression:"!isValid"}],attrs:{"icon":"AlertCircleIcon"}},[_vm._v("\n      "+_vm._s(_vm.lang.botMaker.addBot.manageUsers.dialogSelectUsers.alert[
          _vm.languageSelected
        ])+"\n    ")]),_vm._v(" "),_c('vs-table',{staticClass:"users-list",attrs:{"multiple":"","data":_vm.userList,"search":"","paginated":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].name}},[_vm._v(_vm._s(data[indextr].name))]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].email}},[_vm._v(_vm._s(data[indextr].email))]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].roles}},[_c('ul',_vm._l((tr.roles.ids),function(role,index){return _c('li',{key:index},[_vm._v("\n                - "+_vm._s(_vm._f("role_name")(role,_vm.languageSelected))+"\n              ")])}))])],1)})}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v(_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.name[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"email"}},[_vm._v(_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.email[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"roles"}},[_vm._v(_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.role[_vm.languageSelected]))])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }