<template>
  <div class="add-bot-section">
    <vs-row vs-w="12">
      <vs-col vs-w="8">
        <h2>{{ title }}</h2>
      </vs-col>
      <vs-col vs-w="4">
        <div class="btn-actions">
          <slot name="actions"></slot>
        </div>
      </vs-col>
      <vs-col vs-w="6">
        <slot name="left-side"></slot>
      </vs-col>
      <vs-col vs-w="6">
        <slot name="right-side"></slot>
      </vs-col>
      <vs-col vs-w="12">
        <slot name="whole-w"></slot>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: 'AddBotSection',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.add-bot-section {
  margin: 30px 20px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
