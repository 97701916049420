<template>
  <add-bot-section
    :title="lang.botMaker.addBot.timeZone.title[languageSelected]"
  >
    <template slot="whole-w">
      <TimeZoneSelector v-model="selected" />
    </template>
    <!-- <template slot="whole-w">
      <pre>{{selected}}</pre>
    </template>-->
  </add-bot-section>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import AddBotSection from './Components/AddBotSection'

import Helper from '../../../../store/Helper'
import timeZones from '../../../../constants/_TIME_ZONES.json'

export default {
  name: 'BotTimeZone',
  props: {
    isEditing: Boolean
  },
  components: {
    AddBotSection,
    TimeZoneSelector: () =>
      import('../../../../components/TimeZoneSelector.vue')
  },
  data() {
    return {
      timeZones,
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'sectionReady'])
  },
  watch: {
    selected(newVal) {
      if (newVal && typeof newVal.text === 'string') {
        this.SET_TIME_OFFSET(newVal.text)
      }
    }
  },
  methods: {
    ...mapMutations('bots', ['SET_TIME_OFFSET'])
  },
  mounted() {
    if (
      this.isEditing &&
      typeof this.bot._timeOffset === 'string' &&
      this.timeZones.find(item => item.text === this.bot._timeOffset)
    ) {
      this.selected = this.timeZones.find(
        item => item.text === this.bot._timeOffset
      )
    } else {
      this.sectionReady.botAvailability = true
      this.selected = Helper.getBrowserTimeZone()
    }
  }
}
</script>
