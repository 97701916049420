<template>
  <div id="select-users-prompt">
    <vs-prompt
      :is-valid="isValid"
      :vs-active.sync="openSelectUsers"
      @vs-accept="onSelectUsers"
      @vs-close="onClose"
      @vs-cancel="onClose"
      :vs-accept-text="
        lang.botMaker.addBot.manageUsers.dialogSelectUsers.selectUsers[
          languageSelected
        ]
      "
      :vs-title="
        lang.botMaker.addBot.manageUsers.dialogSelectUsers.title[
          languageSelected
        ]
      "
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
    >
      <kona-alert icon="AlertCircleIcon" v-show="!isValid">
        {{
          lang.botMaker.addBot.manageUsers.dialogSelectUsers.alert[
            languageSelected
          ]
        }}
      </kona-alert>

      <vs-table
        multiple
        v-model="selectedUsers"
        :data="userList"
        class="users-list"
        search
        paginated
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th sort-key="name">{{
            lang.botMaker.addBot.manageUsers.table.name[languageSelected]
          }}</vs-th>
          <vs-th sort-key="email">{{
            lang.botMaker.addBot.manageUsers.table.email[languageSelected]
          }}</vs-th>
          <vs-th sort-key="roles">{{
            lang.botMaker.addBot.manageUsers.table.role[languageSelected]
          }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].roles">
              <ul>
                <li v-for="(role, index) in tr.roles.ids" :key="index">
                  - {{ role | role_name(languageSelected) }}
                </li>
              </ul>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'SelectUsers',
  props: {
    togglePrompt: Function,
    onlyAgents: Boolean
  },
  data() {
    return {
      selectedUsers: [],
      isMultiple: false
    }
  },
  computed: {
    ...mapGetters('bots', ['users', 'bot']),
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    isValid() {
      return this.selectedUsers.length > 0
    },
    openSelectUsers: {
      get() {
        return this.$store.state.bots.isSelectUsersPromptOpened
      },
      set(value) {
        this.UPDATE_SELECT_USER_PROMPT(value)
      }
    },
    userList() {
      // Remove the current user from the list and selected
      const usersSelected = this.bot.service.users.map(u => u.id)
      usersSelected.push(this.session.user._id || this.session.user.id)
      return this.users.filter(u => {
        return !this._.includes(usersSelected, u.id)
      })
    }
  },
  methods: {
    ...mapMutations('bots', ['UPDATE_SELECT_USER_PROMPT', 'ADD_USERS']),
    ...mapActions('bots', ['LOAD_USERS', 'LOAD_AGENTS']),
    onSelectUsers() {
      let users = [...this.bot.service.users]
      users = users.concat(this.selectedUsers)
      users = this._.uniq(users, 'id')

      // Sort users by name
      users = this._.orderBy(users, 'name', 'asc')

      this.ADD_USERS(users)
      if (this.togglePrompt) {
        this.togglePrompt()
      }
    },
    onClose() {
      this.UPDATE_SELECT_USER_PROMPT(false)
      if (this.togglePrompt) {
        this.togglePrompt()
      }
    }
  },
  mounted() {
    this.$vs.loading()
    let self = this
    if (this.onlyAgents) {
      this.LOAD_AGENTS(this.useStaffManagement).finally(() => {
        self.$vs.loading.close()
      })
    } else {
      this.LOAD_USERS().finally(() => {
        self.$vs.loading.close()
      })
    }
  }
}
</script>

<style type="scss">
#select-users-prompt {
  max-height: 300px;
}

.vs-dialog {
  max-width: 700px !important;
}

.users-list {
  max-height: 400px;
  overflow-y: auto;
}

.con-vs-dialog .vs-dialog footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

.con-vs-dialog .vs-dialog footer .vs-button {
  margin-left: 0.5rem;
}
</style>

<style>
#select-users-prompt footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

#select-users-prompt footer .vs-button {
  margin-left: 0.5rem;
}
#select-users-prompt footer span.vs-button-text.vs-button--text {
  color: white !important;
}
</style>
