var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('add-bot-section',{attrs:{"title":_vm.lang.botMaker.addBot.sessionSettings.title[_vm.languageSelected]}},[_c('template',{slot:"whole-w"},[_c('form-group',{staticClass:"settings-form-group",attrs:{"label":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus0[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus0Tooltip[
          _vm.languageSelected
        ]}},[_c('TimeLog',{model:{value:(_vm.bot.sessionTimeoutStatus0),callback:function ($$v) {_vm.$set(_vm.bot, "sessionTimeoutStatus0", $$v)},expression:"bot.sessionTimeoutStatus0"}})],1),_vm._v(" "),_c('form-group',{staticClass:"settings-form-group",attrs:{"label":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus1[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus1Tooltip[
          _vm.languageSelected
        ]}},[_c('TimeLog',{model:{value:(_vm.bot.sessionTimeoutStatus1),callback:function ($$v) {_vm.$set(_vm.bot, "sessionTimeoutStatus1", $$v)},expression:"bot.sessionTimeoutStatus1"}})],1),_vm._v(" "),_c('form-group',{staticClass:"settings-form-group",attrs:{"label":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus2[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus2Tooltip[
          _vm.languageSelected
        ]}},[_c('TimeLog',{model:{value:(_vm.bot.sessionTimeoutStatus2),callback:function ($$v) {_vm.$set(_vm.bot, "sessionTimeoutStatus2", $$v)},expression:"bot.sessionTimeoutStatus2"}})],1),_vm._v(" "),_c('form-group',{staticClass:"settings-form-group",attrs:{"label":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus3[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.sessionSettings.sessionTimeoutStatus3Tooltip[
          _vm.languageSelected
        ]}},[_c('TimeLog',{model:{value:(_vm.bot.sessionTimeoutStatus3),callback:function ($$v) {_vm.$set(_vm.bot, "sessionTimeoutStatus3", $$v)},expression:"bot.sessionTimeoutStatus3"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }