<template>
  <div id="engine-credentials-table" v-if="nlu && !isEditing">
    <vs-divider />
    <p class="m-base">{{ lang.nlu.title[languageSelected] }}</p>
    <vs-table
      pagination
      v-model="selected"
      @selected="handleSelected"
      :data="auxNLU"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="name">{{
          lang.nlu.table.name[languageSelected]
        }}</vs-th>
        <vs-th sort-key="type">
          {{ lang.nlu.table.type[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_updatedAt">
          {{ lang.nlu.table._updatedAt[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_createdAt">
          {{ lang.nlu.table._createdAt[languageSelected] }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
          <vs-td :data="data[indextr].type">
            {{
              data[indextr].type === 'luis'
                ? 'Microsoft LUIS'
                : data[indextr].type === 'watson'
                ? 'IBM Watson'
                : data[indextr].type
            }}
          </vs-td>
          <vs-td :data="data[indextr]._updatedAt">
            {{
              new Date(data[indextr]._updatedAt || data[indextr]._createdAt)
                | date
            }}
          </vs-td>
          <vs-td :data="data[indextr]._createdAt">{{
            new Date(data[indextr]._createdAt) | date
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'EngineCredentialsTable',
  props: {
    isEditing: Boolean
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('credentials', ['nlu']),
    ...mapGetters('bots', ['bot']),
    auxNLU() {
      if (!this.nlu) {
        return []
      }
      return this.nlu.filter(
        element => element.active && element.type === this.bot.nlu.platform
      )
    }
  },
  watch: {
    auxNLU(newVal) {
      if (newVal && newVal.length > 0) {
        this.selected = newVal[0]
        this.SET_CREDENTIAL(newVal[0]._id)
        this.bot.credential = newVal[0]._id
      } else {
        this.bot.credential = undefined
      }
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_CREDENTIAL'
    ]),
    handleSelected(tr) {
      if (tr && tr._id) {
        this.bot.credential = tr._id
        this.SET_CREDENTIAL(tr._id)
      }
    }
  },
  mounted() {
    if (this.auxNLU && this.auxNLU.length > 0) {
      this.selected = this.auxNLU[0]
      this.bot.credential = this.auxNLU[0]._id
      this.SET_CREDENTIAL(this.auxNLU[0]._id)
    } else {
      this.bot.credential = undefined
    }
  }
}
</script>

<style type="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
</style>
