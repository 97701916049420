import { render, staticRenderFns } from "./ForkingTable.vue?vue&type=template&id=556d955a&scoped=true&"
import script from "./ForkingTable.vue?vue&type=script&lang=js&"
export * from "./ForkingTable.vue?vue&type=script&lang=js&"
import style0 from "./ForkingTable.vue?vue&type=style&index=0&id=556d955a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556d955a",
  null
  
)

export default component.exports