<template>
  <vs-row class="form-group">
    <vs-col :vs-w="customCol" class="form-group-label">
      <p>
        {{ label }}
        <vx-tooltip v-if="tooltip" :text="tooltip" position="top">
          <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="ml-2" />
        </vx-tooltip>
      </p>
    </vs-col>
    <vs-col :vs-w="12 - customCol">
      <slot></slot>
    </vs-col>
  </vs-row>
</template>
<script>
export default {
  name: 'FormGroup',
  props: {
    customCol: {
      type: Number,
      default: 2
    },
    label: String,
    tooltip: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  margin-bottom: 20px;
  .form-group-label {
    font-size: 14px;

    p {
      display: flex;
      .con-vs-tooltip {
        display: inline-block;
      }
    }
  }
}
</style>
