<template>
  <div id="days-available">
    <new-form-group
      :label="
        lang.botMaker.addBot.botAvailability.selectWeekDays[languageSelected]
      "
    >
      <WeekDaysHoursAvailable />
    </new-form-group>
    <new-form-group
      :label="
        lang.botMaker.addBot.botAvailability.checkAvailability[languageSelected]
      "
    >
      <vx-slider v-model="bot.checkAvailability" />
    </new-form-group>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NewFormGroup from './../Components/NewFormGroup'

export default {
  name: 'DaysAvailable',
  components: {
    NewFormGroup,
    WeekDaysHoursAvailable: () =>
      import('./components/WeekDaysHoursAvailable.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady'])
  }
}
</script>
