<template>
  <div id="days-exceptions">
    <!--Select dates-->
    <new-form-group
      :label="lang.botMaker.addBot.botAvailability.exceptions[languageSelected]"
    >
      <div class="exception-days-row">
        <datepicker
          :language="datepickerLanguage"
          class="datepicker"
          calendar-class="date-picker-availability"
          v-model="exceptionSelected"
          :disabled-dates="disabledDates"
        ></datepicker>
        <vs-button
          color="primary"
          type="filled"
          class="add-exception-btn"
          @click="addException"
          :disabled="!exceptionSelected"
          icon="add"
        ></vs-button>
      </div>

      <p class="error-message" v-show="existingDate">
        {{
          lang.botMaker.addBot.botAvailability.daysAvailabilityError
            .existingDate[languageSelected]
        }}
      </p>
    </new-form-group>

    <!--Dates selected-->
    <new-form-group
      v-if="hasExceptions"
      :label="
        lang.botMaker.addBot.botAvailability.datesSelected[languageSelected]
      "
    >
      <vs-list class="selected-dates">
        <vs-list-item
          :subtitle="exception | moment('dddd, MMMM Do YYYY')"
          v-for="(exception, index) in bot.availability.exceptions"
          :key="index"
        >
          <vs-button
            type="flat"
            icon="close"
            @click="removeException(index)"
          ></vs-button>
        </vs-list-item>
      </vs-list>
    </new-form-group>

    <!--Availability message-->
    <new-form-group
      :label="lang.botMaker.addBot.botAvailability.message[languageSelected]"
    >
      <vs-textarea
        v-model="bot.availability.message"
        v-bind:class="{ error: validations.availability.messageError }"
      />
      <p class="error-message" v-show="validations.availability.messageError">
        {{
          lang.botMaker.addBot.botAvailability.daysAvailabilityError
            .messageError[languageSelected]
        }}
      </p>
    </new-form-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { en, es } from 'vuejs-datepicker/dist/locale'
import NewFormGroup from '../Components/NewFormGroup'

export default {
  name: 'DaysExceptions',
  components: {
    Datepicker,
    NewFormGroup
  },
  data() {
    return {
      highlighted: { dates: [] },
      exceptionSelected: '',
      disabledDates: {
        to: new Date()
      },
      existingDate: false,
      en: en,
      es: es
    }
  },
  watch: {
    '$store.state.newBotLanguage'() {
      this.UPDATE_AVAILABILITY_MESSAGE()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', ['bot', 'validations']),
    hasExceptions() {
      return this.bot.availability.exceptions.length > 0
    },
    datepickerLanguage() {
      return this.languageSelected === 'es' ? this.es : this.en
    }
  },
  methods: {
    ...mapMutations('bots', [
      'removeDateFromCalendar',
      'UPDATE_AVAILABILITY_MESSAGE'
    ]),
    addException: function() {
      let dateToAdd = this.exceptionSelected

      if (this.bot.availability.hasDateAsException(dateToAdd)) {
        this.existingDate = true
        return
      }

      if (dateToAdd) {
        this.bot.availability.addExceptionDate(dateToAdd)
        this.exceptionSelected = undefined
      }
    },
    removeException: function(index) {
      this.bot.availability.removeDateFromCalendar(index)
      this.highlighted.dates.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
#days-exceptions {
  .exception-days-row {
    display: flex;
    align-items: center;
    .add-exception-btn {
      margin-left: 5px;
    }
  }
  .selected-dates {
    max-height: 300px;
    overflow-y: auto;
  }
  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
}
</style>
<style lang="scss">
.date-picker-availability {
  $konecta-color: #ff8000;
  .cell:hover {
    border: 2px solid $konecta-color !important;
  }
  .selected {
    background-color: $konecta-color !important;
    color: white;
  }
}
</style>
