var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-table"},[_c('vs-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.lang.botMaker.addBot.manageUsers.table.download[_vm.languageSelected]),expression:"lang.botMaker.addBot.manageUsers.table.download[languageSelected]"}],staticClass:"ml-auto mr-0",attrs:{"size":"","disabled":!_vm.usersReport.length,"radius":"","type":"flat","icon":"icon-download","icon-pack":"feather"},on:{"click":_vm.downloadAll}}),_vm._v(" "),_c('vs-table',{attrs:{"data":_vm.users,"pagination":"","max-items":_vm.maxItems,"search":"","hoverFlat":true,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},on:{"items-filtered":_vm.handleFilteredElements},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":tr.name}},[_vm._v(_vm._s(tr.name))]),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.email}},[_vm._v(_vm._s(tr.email))]),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.roles}},[(
              _vm.useStaffManagement && tr.staffRoles && tr.staffRoles.length > 0
            )?_c('ul',_vm._l((tr.staffRoles),function(role,index){return _c('li',{key:index},[_vm._v("\n              - "+_vm._s(role.name)+"\n            ")])})):(
              !_vm.useStaffManagement && tr.roles && tr.roles.ids.length > 0
            )?_c('ul',_vm._l((tr.roles.ids),function(role,index){return _c('li',{key:index},[_vm._v("\n              - "+_vm._s(_vm._f("role_name")(role,_vm.languageSelected))+"\n            ")])})):_vm._e()]),_vm._v(" "),(_vm.showChannels)?_c('vs-td',{attrs:{"data":_vm.usersByChannelMap[tr.id]}},[_c('multiselect',{attrs:{"placeholder":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table
                .selectChannels[_vm.languageSelected],"tagPlaceholder":"","show-labels":false,"label":"name","track-by":"value","options":_vm.USERS_PLATFORMS,"multiple":true,"taggable":true,"limit":2,"limit-text":_vm.limitText},model:{value:(_vm.usersByChannelMap[tr.id]),callback:function ($$v) {_vm.$set(_vm.usersByChannelMap, tr.id, $$v)},expression:"usersByChannelMap[tr.id]"}})],1):_vm._e(),_vm._v(" "),_c('vs-td',[_c('div',{staticClass:"table-actions"},[(_vm.onEdit && (_vm.isAuthAux('konecta.users.edit') || tr.id === _vm.session.user.id))?_c('vs-button',{staticClass:"with-space-to-the-right",attrs:{"color":"warning","type":"flat","icon":"edit"},on:{"click":function($event){_vm.editUser(tr.id)}}}):_vm._e(),_vm._v(" "),(_vm.isAuthAux('konecta.users.delete') && tr.id !== _vm.session.user.id)?_c('vs-button',{attrs:{"disabled":_vm.disableDelete,"color":"warning","type":"flat","icon":"clear"},on:{"click":function($event){_vm.remove(tr.id)}}}):_vm._e()],1)])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.name[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.email[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.role[_vm.languageSelected])+"\n      ")]),_vm._v(" "),(_vm.showChannels)?_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.channels[_vm.languageSelected])+"\n      ")]):_vm._e(),_vm._v(" "),_c('vs-th')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }